import React, { useState, useEffect, useRef } from 'react';
import MapComponent from './MapComponent'; // Update this import
import Sidebar from './Sidebar';
import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { LatLng, LatLngExpression, Map as LeafletMap } from 'leaflet'; // Import LeafletMap
import * as toGeoJSON from '@tmcw/togeojson'; // Import GPX to GeoJSON converter
import fetchApiKey from '../../helpers/FetchAPIKey';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { getCurrentUser } from 'aws-amplify/auth';
import { toast } from 'react-toastify';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { useAuth } from '../../contexts/AuthContext'; // Add this import
import { usePreferences } from '../../contexts/Preferences'; // Add this import

type PopularRoute = {
  coordinates: [number, number][];
  intensity: number;
};

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Maps: React.FC = () => {
  const [waypoints, setWaypoints] = useState<LatLng[]>([]); 
  const [route, setRoute] = useState<LatLng[]>([]);
  const [surfaceType, setSurfaceType] = useState<string>('foot-walking');
  const [selectedDistance, setSelectedDistance] = useState<number>(0);
  const [startFromCurrent, setStartFromCurrent] = useState<boolean>(true);
  const [drawingMode, setDrawingMode] = useState<string>('distance');
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [routeDistance, setRouteDistance] = useState<number>(0);
  const [mapType, setMapType] = useState<string>('standard');
  const [undoneWaypoints, setUndoneWaypoints] = useState<LatLng[]>([]);
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentLocation, setCurrentLocation] = useState<LatLng | null>(null);
  const [showElevation, setShowElevation] = useState<boolean>(false);
  const [elevationData, setElevationData] = useState<{ distance: number; elevation: number }[]>([]);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(true);
  const [heatmapData, setHeatmapData] = useState<{ lat: number; lng: number; intensity: number }[]>([]);
  const urlLocation = useLocation();
  const [popularRoutes, setPopularRoutes] = useState<PopularRoute[]>([]);
  const [showPopularRoutes, setShowPopularRoutes] = useState<boolean>(false);
  const [isOffline, setIsOffline] = useState<boolean>(false);
  const [savedGpxFiles, setSavedGpxFiles] = useState<{ [key: string]: string }>({});
  const [isKm, setIsKm] = useState<boolean>(true);
  const [distanceMarkers, setDistanceMarkers] = useState([]);
  const [showDistanceMarkers, setShowDistanceMarkers] = useState<boolean>(true);
  const navigate = useNavigate();
  const [visibleMarkers, setVisibleMarkers] = useState([]);
  const [routeName, setRouteName] = useState<string>(''); // New state for route name
  const [showWaypoints, setShowWaypoints] = useState<boolean>(!window.location.search.includes('loadRoute'));
  const [map, setMap] = useState<LeafletMap | null>(null); // Explicitly type map as LeafletMap or null
  const [searchAddress, setSearchAddress] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showSaveModal, setShowSaveModal] = useState(false);

  const provider = useRef(new OpenStreetMapProvider());
  const mapRef = useRef(null);

  const { user } = useAuth();
  const { preferences, fetchPreferences } = usePreferences();

  useEffect(() => {
    let watchId: number;

    const handlePermissionDenied = () => {
      toast.error("Location access denied. Some features may not work as expected.");
      const fallbackLocation = new LatLng(51.505, -0.09);
      setCurrentLocation(fallbackLocation);
      if (waypoints.length === 0) {
        setWaypoints([fallbackLocation]);
      }
    };

    const watchLocation = () => {
      if (navigator.geolocation) {
        watchId = navigator.geolocation.watchPosition(
          (position) => {
            const location = new LatLng(position.coords.latitude, position.coords.longitude);
            setCurrentLocation(location);
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              handlePermissionDenied();
            } else {
              console.error('Geolocation error:', error);
            }
          },
          {
            enableHighAccuracy: true,
            maximumAge: 0,
            timeout: 5000,
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        const fallbackLocation = new LatLng(51.505, -0.09);
        setCurrentLocation(fallbackLocation);
        if (waypoints.length === 0) {
          setWaypoints([fallbackLocation]);
        }
      }
    };
    watchLocation();

    return () => {
      if (navigator.geolocation && watchId !== undefined) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, []);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const gpxContent = e.target?.result as string;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(gpxContent, 'application/xml');
        const geoJsonData = toGeoJSON.gpx(xmlDoc);
        
        const newWaypoints = geoJsonData.features
          .filter((feature: any) => feature.geometry.type === 'LineString')
          .flatMap((feature: any) => 
            feature.geometry.coordinates.map((coord: [number, number]) => new LatLng(coord[1], coord[0]))
          );
        
        setWaypoints(newWaypoints);
        setRoute(newWaypoints);
        setCurrentStep(5); // Step 5 is the File Uploaded screen
      };
      reader.readAsText(file);
    }
  };

  const getCityFromCoordinates = async (location: LatLng): Promise<string> => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${location.lat}&lon=${location.lng}&zoom=10`
      );

      if (response.data && response.data.address) {
        // Try to get the city, town, or village name
        return response.data.address.city || 
               response.data.address.town || 
               response.data.address.village || 
               'Unknown';
      }
      return 'Unknown';
    } catch (error) {
      console.error('Error fetching city name:', error);
      return 'Unknown';
    }
  };

  const undoWaypoint = () => {
    if (waypoints.length > 1) {
      const lastWaypoint = waypoints[waypoints.length - 1];
      setWaypoints(waypoints.slice(0, -1));
      setUndoneWaypoints([lastWaypoint, ...undoneWaypoints]);
    }
  };
  
  const redoWaypoint = () => {
    if (undoneWaypoints.length > 0) {
      const lastUndoneWaypoint = undoneWaypoints[0];
      setWaypoints([...waypoints, lastUndoneWaypoint]);
      setUndoneWaypoints(undoneWaypoints.slice(1));
    }
  };

  const generateGPX = (route: LatLng[]) => {
    const gpxHeader = `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>
      <gpx version="1.1" creator="Running Route Planner" xmlns="http://www.topografix.com/GPX/1/1">
      <metadata>
        <name>Running Route</name>
        <desc>A running route planned with Running Route Planner</desc>
      </metadata>
      <trk><name>Route</name><trkseg>`;

    const gpxWaypoints = route.map(point => `
        <trkpt lat="${point.lat}" lon="${point.lng}">
          <ele>0</ele>
          <time>${new Date().toISOString()}</time>
        </trkpt>`).join('');
  
    const gpxFooter = `</trkseg></trk></gpx>`;
  
    const gpxContent = gpxHeader + gpxWaypoints + gpxFooter;
  
    // Create a Blob with GPX content and save it as a file
    const blob = new Blob([gpxContent], { type: 'application/gpx+xml' });
    saveAs(blob, 'route.gpx');
  };

  useEffect(() => {
    const fetchKey = async () => {
      try {
        const key = await fetchApiKey();
        setApiKey(key);
      } catch (error) {
        console.error('Failed to fetch API key');
      } finally {
        setLoading(false);
      }
    };

    fetchKey();
  }, []);

  useEffect(() => {
    document.title = 'Running Route Planner - Plan Your Perfect Run';
  }, []);

  useEffect(() => {
    if (route.length > 1 && drawingMode === 'free') {
      const distance = calculateDistance(route);
      setRouteDistance(distance);
    }
    if (route.length === 0) {
      setRouteDistance(0);
    }
  }, [route, drawingMode]);

  const location = useLocation();

  useEffect(() => {
    if (location.state?.savingRoute) {
      const pendingRoute = localStorage.getItem('pendingRoute');
      if (pendingRoute) {
        const parsedRoute = JSON.parse(pendingRoute).map(
          (point: { lat: number; lng: number }) => new LatLng(point.lat, point.lng)
        );
        setRoute(parsedRoute);
        setCurrentStep(3);
        setShowSaveModal(true);
      }
    }
    const params = new URLSearchParams(location.search);
    const loadRouteParam = params.get('loadRoute');
    
    if (loadRouteParam) {
      loadSavedRoute(decodeURIComponent(loadRouteParam));
      setShowWaypoints(false);
    }
  }, [location.search]);

  const loadSavedRoute = async (fileName: string) => {
    try {
      const response = await axios.get('https://dd1zrrqamr91n.cloudfront.net/prod/fetchRoute', {
        params: { routeId: fileName }
      });

      if (response.data && response.data.route) {
        const newWaypoints = response.data.route.map(
          (coord: [number, number]) => new LatLng(coord[0], coord[1])
        );
        
        setWaypoints(newWaypoints);
        setRoute(newWaypoints);
        setRouteName(response.data.routeName);
        setCurrentStep(6);
      } else {
        throw new Error('No route data found');
      }
    } catch (error) {
      console.error('Error loading route:', error);
      toast.error('Failed to load route. Please try again later.');
    }
  };

  const calculateDistance = (route: LatLng[]): number => {
    if (route.length < 2) return 0;

    let totalDistance = 0;
    for (let i = 1; i < route.length; i++) {
      totalDistance += route[i - 1].distanceTo(route[i]);
    }

    return parseFloat((totalDistance / 1000).toFixed(2)); // Convert to kilometers and return as a number
  };

  const resetRoute = () => {
    setWaypoints([]);
    setRoute([]);
    setCurrentStep(1);
    setDistanceMarkers([]);
    setVisibleMarkers([]);
    navigate('/running-route-planner');
  };

  const resetRouteMaintainStartPoint = () => {
    setWaypoints(waypoints => waypoints.slice(0, 1));
    setRoute([]);
    setCurrentStep(2);
    setDistanceMarkers([]);
    setVisibleMarkers([]);
    navigate('/running-route-planner');
  };

  const previousStepHandler = () => {
    if (currentStep === 3) {
      resetRouteMaintainStartPoint();
      return;
    }
    setCurrentStep((prev) => prev - 1);
  };

  const toggleElevationDisplay = () => {
    setShowElevation(!showElevation);
    setSidebarOpen(false); // Close the sidebar when showing elevation
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    if (showElevation) {
      setShowElevation(false); // Hide elevation data when opening sidebar
    }
  };

  const fetchPopularRoutes = async (bounds: L.LatLngBounds) => {
    const query = `
      [out:json];
      (
        way["highway"="footway"]["footway"="sidewalk"](${bounds.getSouth()},${bounds.getWest()},${bounds.getNorth()},${bounds.getEast()});
        way["highway"="path"]["foot"="designated"](${bounds.getSouth()},${bounds.getWest()},${bounds.getNorth()},${bounds.getEast()});
        way["highway"="cycleway"](${bounds.getSouth()},${bounds.getWest()},${bounds.getNorth()},${bounds.getEast()});
      );
      out geom;
    `;

    try {
      const response = await axios.get('https://overpass-api.de/api/interpreter', {
        params: { data: query }
      });

      const routes = response.data.elements.map((element: any) => ({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: element.geometry.map((node: any) => [node.lon, node.lat])
        },
        properties: {
          id: element.id,
          tags: element.tags
        }
      }));

      setPopularRoutes(routes);
    } catch (error) {
      console.error('Error fetching popular routes:', error);
    }
  };

  const togglePopularRoutes = () => {
    setShowPopularRoutes(!showPopularRoutes);
  };

  const toggleOfflineMode = () => {
    setIsOffline(!isOffline);
  };

  useEffect(() => {
    const savedFiles = JSON.parse(localStorage.getItem('savedGpxFiles') || '{}');
    setSavedGpxFiles(savedFiles);
  }, []);

  const loadSavedGpxFile = (content: string) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(content, 'application/xml');
    const geoJsonData = toGeoJSON.gpx(xmlDoc);
    
    const newWaypoints = geoJsonData.features
      .filter((feature: any) => feature.geometry.type === 'LineString')
      .flatMap((feature: any) => 
        feature.geometry.coordinates.map((coord: [number, number]) => new LatLng(coord[1], coord[0]))
      );
    
    setWaypoints(newWaypoints);
    setRoute(newWaypoints);
    setCurrentStep(5);
  };

  const saveRoute = async (routeName: string): Promise<string> => {
    if (route.length === 0) {
      throw new Error("Cannot save an empty route");
    }

    try {
      const currentUser = await getCurrentUser();
      const gpxContent = generateGPXContent(route);
      const location = route[0];

      const response = await axios.post('https://dd1zrrqamr91n.cloudfront.net/prod/saveRoute', {
        routeName,
        gpxContent,
        location,
        userId: currentUser?.userId || 'anonymous',
        username: currentUser?.username || 'anonymous'
      });

      const routeId = response.data.routeId;
      navigate(`/running-route-planner?loadRoute=${routeId}`);
      return routeId;
    } catch (error) {
      console.error("Error saving route:", error);
      throw error;
    }
  };

  const generateGPXContent = (route: LatLng[]) => {
    const gpxHeader = `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>
      <gpx version="1.1" creator="Running Route Planner" xmlns="http://www.topografix.com/GPX/1/1">
      <metadata>
        <name>Running Route</name>
        <desc>A running route planned with Running Route Planner</desc>
      </metadata>
      <trk><name>Route</name><trkseg>`;

    const gpxWaypoints = route.map(point => `
        <trkpt lat="${point.lat}" lon="${point.lng}">
          <ele>0</ele>
          <time>${new Date().toISOString()}</time>
        </trkpt>`).join('');
  
    const gpxFooter = `</trkseg></trk></gpx>`;
  
    return gpxHeader + gpxWaypoints + gpxFooter;
  };

  const handleViewportChanged = (bounds: L.LatLngBounds) => {
    if (showPopularRoutes) {
      fetchPopularRoutes(bounds);
    }
  };

  const handleDistanceMarkersToggle = () => {
    setShowDistanceMarkers(!showDistanceMarkers);
  };

  useEffect(() => {
    if (user) {
      fetchPreferences(user.username);
    }
  }, [user, fetchPreferences]);

  useEffect(() => {
    if (preferences) {
      setIsKm(preferences.isKm);
    }
  }, [preferences]);

  const handleAddressSearch = async (address: string) => {
    if (!address) return;

    try {
      const results = await provider.current.search({ query: address });

      if (results.length > 0) {
        const { x, y, label } = results[0];
        const newStartPoint = new LatLng(y, x);
        setWaypoints([newStartPoint]);
        setCurrentStep(2);
        
        // Use optional chaining to ensure 'map' is not null before calling setView
        map?.setView([y, x], 13);
        
        setSearchAddress(label);
        setSuggestions([]);
      } else {
        alert('Address not found. Please try a different search term.');
      }
    } catch (error) {
      console.error('Error searching for address:', error);
      alert('An error occurred while searching for the address. Please try again.');
    }
  };

  return (
    <PageContainer>
      <Helmet>
        <title>Running Route Planner | Create and Share Your Running Routes</title>
        <meta 
          name="description" 
          content="Plan your perfect running route with our interactive running route planner. Create, save, and share custom routes for all your running needs."
        />
        <meta 
          name="keywords" 
          content="running route planner, create running routes, plan running routes, running map, running route creator"
        />
      </Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebApplication",
          "name": "Running Route Planner",
          "description": "Plan and create custom running routes with our interactive map tool.",
          "url": "https://airunningtraining.com/running-route-planner",
          "applicationCategory": "Sports",
          "operatingSystem": "Web"
        })}
      </script>
      <Sidebar
        resetRoute={resetRoute}
        currentStep={currentStep}
        goToNextStep={() => setCurrentStep((prev) => prev + 1)}
        goToPreviousStep={() => previousStepHandler()}
        onSurfaceChange={setSurfaceType}
        onDistanceChange={setSelectedDistance}
        onStartFromCurrentChange={setStartFromCurrent}
        onDrawingModeChange={setDrawingMode}
        startFromCurrent={startFromCurrent}
        drawingMode={drawingMode}
        distance={selectedDistance}
        routeDistance={routeDistance}
        mapType={mapType}
        setMapType={setMapType}
        route={route}
        generateGPX={generateGPX}
        undoWaypoint={undoWaypoint}
        redoWaypoint={redoWaypoint}
        handleFileUpload={handleFileUpload}
        setWaypoints={setWaypoints}
        currentLocation={currentLocation}
        showElevation={showElevation}
        toggleElevationDisplay={toggleElevationDisplay}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={toggleSidebar}
        showPopularRoutes={showPopularRoutes}
        togglePopularRoutes={togglePopularRoutes}
        isOffline={isOffline}
        toggleOfflineMode={toggleOfflineMode}
        loadSavedGpxFile={loadSavedGpxFile}
        savedGpxFiles={savedGpxFiles}
        saveRoute={saveRoute}
        isKm={isKm}
        setIsKm={setIsKm}
        showDistanceMarkers={showDistanceMarkers}
        handleDistanceMarkersToggle={handleDistanceMarkersToggle}
        loadedRouteName={routeName}
        showWaypoints={showWaypoints}
        setShowWaypoints={setShowWaypoints}
        handleAddressSearch={handleAddressSearch}
        searchAddress={searchAddress}
        suggestions={suggestions}
        setSearchAddress={setSearchAddress}
        setSuggestions={setSuggestions}
        showSaveModal={showSaveModal}
        setShowSaveModal={setShowSaveModal}
      />
      <MapComponent
        waypoints={waypoints}
        setWaypoints={setWaypoints}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        weatherApiKey={process.env.REACT_APP_WEATHER_API_KEY}
        drawingMode={drawingMode}
        setDrawingMode={setDrawingMode}
        surfaceType={surfaceType}
        selectedDistance={selectedDistance}
        route={route}
        setRoute={setRoute}
        mapType={mapType}
        apiKey={process.env.REACT_APP_ELEVATION_API_KEY}
        loading={loading}
        currentLocation={currentLocation}
        showElevation={showElevation}
        setSidebarVisible={toggleSidebar}
        heatmapData={heatmapData}
        popularRoutes={popularRoutes}
        setPopularRoutes={setPopularRoutes}
        showPopularRoutes={showPopularRoutes}
        isOffline={isOffline}
        onViewportChanged={handleViewportChanged}
        isKm={isKm}
        distanceMarkers={distanceMarkers}
        setDistanceMarkers={setDistanceMarkers}
        showDistanceMarkers={showDistanceMarkers}
        visibleMarkers={visibleMarkers}
        setVisibleMarkers={setVisibleMarkers}
        showWaypoints={showWaypoints}
        mapRef={mapRef}
        map={map}
        setMap={setMap}
      />
    </PageContainer>
  );
};

export default Maps;
