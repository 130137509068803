/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { MapContainer, TileLayer, useMapEvents, Polyline, Marker, ZoomControl, GeoJSON, useMap, Popup } from 'react-leaflet';
import { Helmet } from 'react-helmet';
import fetchApiKey from '../../helpers/FetchAPIKey';
import L, { DivIcon, Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import ElevationProfile from './ElevationProfile';
import axios from 'axios';
import PopularRoutesLayer from './PopularRoutesLayer';
import OfflineMap from './OfflineMap';
import { calculatePace } from '../../helpers/paceCalculator.tsx';
import { sendEvent } from '../../utils/analytics';
import 'leaflet-geosearch/dist/geosearch.css';
import { useAuth } from '../../contexts/AuthContext';

import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Fixing the default icon paths
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

// Styled components using Emotion
const PageContainer = styled.div`
  position: relative;
  height: calc(100vh - 56px);
  margin-top: 25px;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledMapContainer = styled(MapContainer)`
  flex: 1;
  width: 100%;
`;

const ElevationProfileContainer = styled.div`
  width: 100%;
  height: 200px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.1);

  @media (max-width: 768px) {
    height: 150px;
    padding: 10px;
  }
`;

const WeatherSuggestionContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 1000;

  @media (max-width: 768px) {
    top: 10px;
    left: 10px;
    right: auto;
    bottom: auto;
  }
`;

const LoadingSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// Define a custom DivIcon for the current location
const currentLocationIcon = new DivIcon({
  html: `
    <div class="current-location-marker">
      <div class="pulse"></div>
      <div class="dot"></div>
    </div>
  `,
  className: '', // Remove default class
  iconSize: [30, 30],
  iconAnchor: [15, 15],
});

// Add corresponding CSS styles
const styles = `
.current-location-marker {
  position: relative;
  width: 30px;
  height: 30px;
}

.current-location-marker .pulse {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  background: rgba(66, 133, 244, 0.4);
  border: 2px solid #4285f4;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 2s infinite;
}

.current-location-marker .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background: #4285f4;
  border: 2px solid white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}

.distance-marker-icon {
  background: none;
  border: none;
}

.distance-marker-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid #4285f4;
  font-size: 9px;
  font-weight: bold;
  color: #4285f4;
}

.waypoint-marker {
  background: none;
  border: none;
}

.waypoint-marker-inner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #4285f4;  // Google Blue
  border: 2px solid white;
  box-shadow: 0 0 0 2px #4285f4;
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.waypoint-marker-inner:hover {
  background-color: #3367d6;  // Darker shade for hover
}

.waypoint-marker-inner:active {
  cursor: grabbing;
}
`;

// Inject styles into the document head
const styleSheet = document.createElement("style")
styleSheet.type = "text/css"
styleSheet.innerText = styles
document.head.appendChild(styleSheet)

// Update the waypointIcon definition to be a function that takes the waypoint number
const waypointIcon = (number) => new DivIcon({
  className: 'waypoint-marker',
  html: `<div class="waypoint-marker-inner">${number}</div>`,
  iconSize: [20, 20],
  iconAnchor: [10, 10]
});

const MapComponent = ({
  waypoints,
  setWaypoints,
  currentStep,
  setCurrentStep,
  drawingMode,
  setDrawingMode,
  surfaceType,
  selectedDistance,
  route,
  setRoute,
  mapType,
  apiKey,
  loading,
  currentLocation,
  showElevation,
  setSidebarVisible,
  weatherApiKey,
  heatmapData,
  popularRoutes,
  setPopularRoutes,
  showPopularRoutes,
  isOffline,
  onViewportChanged,
  isKm,
  distanceMarkers,
  setDistanceMarkers,
  showDistanceMarkers,
  visibleMarkers,
  setVisibleMarkers,
  showWaypoints,
  mapRef,
  map,
  setMap,
}) => {
  const [elevationData, setElevationData] = useState([]);
  const urlLocation = useLocation();
  const [weatherSuggestion, setWeatherSuggestion] = useState(null);
  const [totalElevationGain, setTotalElevationGain] = useState(0);
  const [estimatedPace, setEstimatedPace] = useState(null);
  const [showLoading, setShowLoading] = useState(true);
  const [mapKey, setMapKey] = useState(0); // Add a state variable to trigger re-render
  const routeCenteredRef = useRef(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const loadRouteParam = params.get('loadRoute');

  // Add this line to get isPremium from AuthContext
  const { isPremium } = useAuth();

  // Memoize the updateVisibleMarkers function
  const updateVisibleMarkers = useCallback((newZoom) => {
    if (!showDistanceMarkers || distanceMarkers.length === 0) return;

    let interval;
    if (newZoom <= 11) interval = 5;
    else if (newZoom <= 13) interval = 2;
    else interval = 1;

    const filtered = distanceMarkers.filter((marker) => {
      const distance = parseFloat(marker.distance);
      if (interval === 5) {
        return distance % 5 === 0;
      } else if (interval === 2) {
        return distance % 2 === 0;
      } else {
        return true;
      }
    });

    // Prevent unnecessary state updates
    setVisibleMarkers((prevMarkers) => {
      // Convert arrays to strings for comparison
      const prev = JSON.stringify(prevMarkers);
      const current = JSON.stringify(filtered);
      if (prev !== current) {
        return filtered;
      }
      return prevMarkers;
    });
  }, [distanceMarkers, showDistanceMarkers]);

  // Modify the MapSetter component
  const MapSetter = () => {
    const mapInstance = useMap();
    
    useEffect(() => {
      if (mapInstance) {
        mapRef.current = mapInstance;
        setMap(mapInstance);

        const handleZoomEnd = () => {
          updateVisibleMarkers(mapInstance.getZoom());
        };

        mapInstance.on('zoomend', handleZoomEnd);
        updateVisibleMarkers(mapInstance.getZoom());

        // Center the map on the route when it's loaded
        if (route.length > 0 && !routeCenteredRef.current && loadRouteParam) {
          const bounds = L.latLngBounds(route);
          mapInstance.fitBounds(bounds, { padding: [50, 50] });
          routeCenteredRef.current = true;
        }

        return () => {
          mapInstance.off('zoomend', handleZoomEnd);
        };
      }
    }, [mapInstance, route, loadRouteParam]);

    return null;
  };

  // Effect to pan the map to the current location
  useEffect(() => {
    if (map && currentLocation && waypoints.length === 0) {
      map.setView([currentLocation.lat, currentLocation.lng], map.getZoom(), {
        animate: true,
      });
    }
  }, [map]);

  // Modify the existing useEffect
  useEffect(() => {
    if (map && showPopularRoutes) {
      const updateViewport = () => {
        const bounds = map.getBounds();
        onViewportChanged(bounds);
      };

      map.on('moveend', updateViewport);
      updateViewport(); // Initial fetch

      return () => {
        map.off('moveend', updateViewport);
      };
    } else {
      console.log('Map is not available yet');
    }
  }, [showPopularRoutes]);

  const MapClickHandler = () => {
    useMapEvents({
      click(e) {
        if (currentStep === 1) {
          setWaypoints([e.latlng]); // Set the first waypoint
          setCurrentStep(2); // Move to the next step
        } else if (currentStep === 2) {
          setDrawingMode('free');
          setWaypoints([...waypoints, e.latlng]); // Add waypoint in free drawing mode
          setCurrentStep(3); // Move to the next step
        } else if (currentStep === 3 && drawingMode === 'free') {
          setWaypoints([...waypoints, e.latlng]); // Add waypoint in free drawing mode
        }
      },
    });
    return null;
  };

  const fetchElevationData = async (coordinates) => {
    try {
      const response = await axios.post('https://api.open-elevation.com/api/v1/lookup', {
        locations: coordinates.map(coord => ({ latitude: coord[0], longitude: coord[1] }))
      });
      return response.data.results;
    } catch (error) {
      console.error('Error fetching elevation data:', error);
      return null;
    }
  };

  // Modify the existing useEffect that calculates the route
  useEffect(() => {
    if (currentStep === 3 && (drawingMode === 'free' || drawingMode === 'distance')) {
      calculateRoute();
    }
  }, [waypoints, selectedDistance, surfaceType, drawingMode, currentStep, apiKey, isKm]);

  // Update the useEffect that creates distance markers
  useEffect(() => {
    const markers = createDistanceMarkers(route);
    setDistanceMarkers(markers);
    if (map) {
      updateVisibleMarkers(map.getZoom());
    }
  }, [route, isKm, waypoints, currentStep, map]);

  // Modify the calculateRoute function
  const calculateRoute = () => {
    if (!apiKey) {
      console.error('API key is missing');
      return;
    }

    let url = 'https://api.openrouteservice.org/v2/directions/' + surfaceType;

    const getPointString = (point) => {
      if (Array.isArray(point)) return `${point[1]},${point[0]}`;
      if (typeof point === 'object' && point !== null) return `${point.lng},${point.lat}`;
      return '';
    };

    let requestBody;

    if (drawingMode === 'distance') {
      // Add null checks and default values
      const point = waypoints[0] ? getPointString(waypoints[0]) : 
                    (currentLocation ? `${currentLocation.lng},${currentLocation.lat}` : '');
      
      // Ensure we have a valid point before proceeding
      if (!point) {
        console.error('No valid starting point available');
        return;
      }

      // Calculate the number of points based on the route length
      const pointsPerKm = 1; // Adjust this value to change the density of points
      const calculatedPoints = Math.max(5, Math.round(selectedDistance * pointsPerKm));

      const trip_length = isKm ? (selectedDistance * 1000) : (selectedDistance * 1600)

      requestBody = {
        coordinates: [[parseFloat(point.split(',')[0]), parseFloat(point.split(',')[1])]],
        options: {
          round_trip: {
            length: trip_length,
            seed: 1
          }
        },
      };
    } else if (waypoints.length >= 2) {
      requestBody = {
        coordinates: waypoints.map(point => [point.lng, point.lat])
      };
    } else {
      setRoute([]);
      return;
    }

    axios.post(url, requestBody, {
      headers: {
        'Authorization': apiKey,
        'Content-Type': 'application/json'
      },
      params: {
        elevation: true
      }
    })
    .then(async response => {
      sendEvent('route_calculated', { 
        distance: response.data.routes[0].summary.distance,
        duration: response.data.routes[0].summary.duration
      });
      
      if (response.data && response.data.routes && response.data.routes.length > 0) {
        const route = response.data.routes[0];
        if (route.geometry) {
          const decodedCoordinates = decodePolyline(route.geometry);
          const newRoute = decodedCoordinates.map(coord => new L.LatLng(coord[0], coord[1]));
          setRoute(newRoute);
          
          // Remove this line as we'll create markers in the new useEffect
          // const markers = createDistanceMarkers(newRoute);
          // setDistanceMarkers(markers);

          // Fetch elevation data from Open-Elevation API
          const elevationResults = await fetchElevationData(decodedCoordinates);
          if (elevationResults) {
            const processedElevationData = processElevationData(elevationResults, newRoute);
            setElevationData(processedElevationData);
            
            // Calculate total elevation gain
            const elevationGain = calculateElevationGain(processedElevationData);
            setTotalElevationGain(elevationGain);

            // Estimate pace based on elevation changes
            const pace = calculatePace(processedElevationData, route.summary.distance);
            setEstimatedPace(pace);
          } else {
            console.warn('Failed to fetch elevation data');
            setElevationData([]);
          }
        } else {
          throw new Error('No geometry found in the response');
        }
      } else if (response.data && response.data.error) {
        throw new Error(`API Error: ${response.data.error.message}`);
      } else {
        throw new Error('Unexpected response structure');
      }
    })
    .catch(error => {
      console.error('Error fetching route:', error);
    });
  };

  const processElevationData = (elevationResults, route) => {
    let totalDistance = 0;
    const samplingInterval = Math.max(1, Math.floor(elevationResults.length / 100));

    return elevationResults.reduce((acc, point, index) => {
      if (index > 0 && index < route.length) {
        const prevPoint = route[index - 1];
        const currPoint = route[index];
        totalDistance += L.latLng(prevPoint).distanceTo(L.latLng(currPoint)) / 1000;
      }

      if (index % samplingInterval === 0 || index === elevationResults.length - 1) {
        acc.push({
          elevation: point.elevation,
          distance: parseFloat(totalDistance.toFixed(2)),
        });
      }

      return acc;
    }, []);
  };

  const calculateElevationGain = (elevationData) => {
    let gain = 0;
    for (let i = 1; i < elevationData.length; i++) {
      const elevationDiff = elevationData[i].elevation - elevationData[i-1].elevation;
      if (elevationDiff > 0) {
        gain += elevationDiff;
      }
    }
    return gain;
  };

  // Add this function to decode the polyline
  function decodePolyline(str, precision = 5) {
    var index = 0,
        lat = 0,
        lng = 0,
        coordinates = [],
        shift = 0,
        result = 0,
        byte = null,
        latitude_change,
        longitude_change,
        factor = Math.pow(10, precision);

    while (index < str.length) {
      byte = null;
      shift = 0;
      result = 0;

      do {
        byte = str.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      latitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

      shift = result = 0;

      do {
        byte = str.charCodeAt(index++) - 63;
        result |= (byte & 0x1f) << shift;
        shift += 5;
      } while (byte >= 0x20);

      longitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1));

      lat += latitude_change;
      lng += longitude_change;

      coordinates.push([lat / factor, lng / factor]);
    }

    return coordinates;
  }

  const fetchWeatherAndSuggestTime = async () => {
    if (!weatherApiKey || waypoints.length === 0) return;

    const lat = waypoints[0].lat;
    const lon = waypoints[0].lng;
    const url = `https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lon}&appid=${weatherApiKey}&units=metric`;

    try {
      const response = await axios.get(url);
      const forecasts = response.data.list;

      const suitableForecasts = forecasts.filter(forecast => {
        const temp = forecast.main.temp;
        const windSpeed = forecast.wind.speed;
        const rain = forecast.rain ? forecast.rain['3h'] : 0;
        const time = new Date(forecast.dt * 1000);
        const hour = time.getHours();

        return temp >= 5 && temp <= 20 && windSpeed < 7 && rain < 0.5 && hour >= 5 && hour <= 21;
      });

      if (suitableForecasts.length > 0) {
        const bestForecast = suitableForecasts[0];
        const suggestedTime = new Date(bestForecast.dt * 1000);
        setWeatherSuggestion(`Suggested run time: ${suggestedTime.toLocaleString()}`);
      } else {
        setWeatherSuggestion("No ideal running conditions found in the next 5 days.");
      }
    } catch (error) {
      console.error('Error fetching weather data:', error);
      setWeatherSuggestion("Unable to fetch weather data.");
    }
  };

  useEffect(() => {
    if (waypoints.length > 0 && isPremium) {
      fetchWeatherAndSuggestTime();
    }
  }, [waypoints, weatherApiKey, isPremium]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
      if (!waypoints || waypoints.length === 0) {
        setMapKey(prevKey => prevKey + 1); // Trigger re-render if map fails to load
      }
    }, 5000); // 5 seconds timeout

    return () => clearTimeout(timer);
  }, []);

  const getTileLayer = (mapType) => {
    switch (mapType) {
      case 'satellite':
        return (
          <TileLayer
            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
            attribution= 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
          />
        );
      default: // 'standard'
        return (
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; OpenStreetMap contributors"
          />
        );
    }
  };

  const PopularRoutes = ({ routes }) => {
    return (
      <GeoJSON
        data={{
          type: 'FeatureCollection',
          features: routes
        }}
        style={() => ({
          color: '#ff7800',
          weight: 3,
          opacity: 0.65
        })}
      />
    );
  };

  // Updated function to safely get the center coordinates
  const getMapCenter = () => {
    if (waypoints && waypoints.length > 0 && waypoints[0]) {
      return [waypoints[0].lat, waypoints[0].lng];
    }
    if (currentLocation) {
      return [currentLocation.lat, currentLocation.lng];
    }
    return [51.505, -0.09]; // Default center (London)
  };

  const isRouteLoaded = () => {
    return loadRouteParam !== null;
  }

  // Update the createDistanceMarkers function
  const createDistanceMarkers = (route) => {
    if (!route || route.length < 2) return [];

    const markers = [];
    let totalDistance = 0;
    const interval = isKm ? 1000 : 1609.34; // 1km or 1 mile in meters

    for (let i = 1; i < route.length; i++) {
      const prevPoint = route[i - 1];
      const currPoint = route[i];
      const segmentDistance = prevPoint.distanceTo(currPoint);
      totalDistance += segmentDistance;

      if (totalDistance >= markers.length * interval + interval) {
        const ratio = ((markers.length + 1) * interval - (totalDistance - segmentDistance)) / segmentDistance;
        const markerLat = prevPoint.lat + ratio * (currPoint.lat - prevPoint.lat);
        const markerLng = prevPoint.lng + ratio * (currPoint.lng - prevPoint.lng);
        markers.push({
          position: [markerLat, markerLng],
          distance: isKm ? 
            ((markers.length + 1) * (interval / 1000)).toFixed(1) : 
            ((markers.length + 1) * (interval / 1609.34)).toFixed(1)
        });
      }
    }

    return markers;
  };

  // Update the distanceIcon creation
  const distanceIcon = (distance) => new L.DivIcon({
    className: 'distance-marker-icon',
    html: `<div class="distance-marker-inner">${distance}</div>`,
    iconSize: [24, 24],
    iconAnchor: [12, 12]
  });

  return (
    <PageContainer>
      <ContentContainer>
        {isOffline ? (
          <OfflineMap 
            key={mapKey} 
            center={getMapCenter()} 
            zoom={13} 
          >
            <MapSetter />
            <MapClickHandler />
            {waypoints && waypoints.length > 0 && <Marker position={waypoints[0]} />}
            {currentLocation && (waypoints.length === 0 || isRouteLoaded()) && (
              <Marker 
                position={[currentLocation.lat, currentLocation.lng]} 
                icon={currentLocationIcon} 
              />
            )}
            <Polyline positions={route} color="blue" />
            <ZoomControl position="topright" />
            {showDistanceMarkers && visibleMarkers.map((marker, index) => (
              <Marker 
                key={index} 
                position={marker.position} 
                icon={distanceIcon(marker.distance)}
              >
                <Popup>{marker.distance} {isKm ? 'km' : 'mi'}</Popup>
              </Marker>
            ))}
            {showWaypoints && waypoints.map((waypoint, index) => (
              <Marker 
                key={`waypoint-${index}`} 
                position={waypoint} 
                icon={index === 0 ? new L.Icon.Default() : waypointIcon(index + 1)}
                draggable={true}
                eventHandlers={{
                  dragend: (e) => {
                    const marker = e.target;
                    const position = marker.getLatLng();
                    // Update the waypoint position in your state
                    const newWaypoints = [...waypoints];
                    newWaypoints[index] = position;
                    setWaypoints(newWaypoints);
                  },
                }}
              >
                <Popup>Waypoint {index + 1}</Popup>
              </Marker>
            ))}
          </OfflineMap>
        ) : (
          <StyledMapContainer 
            key={mapKey} 
            center={getMapCenter()} 
            zoom={13} 
            zoomControl={false} 
          >
            <MapSetter />
            {getTileLayer(mapType)}
            <MapClickHandler />
            {waypoints && waypoints.length > 0 && <Marker position={waypoints[0]} />}
            {currentLocation && (waypoints.length === 0 || isRouteLoaded()) && (
              <Marker 
                position={[currentLocation.lat, currentLocation.lng]} 
                icon={currentLocationIcon} 
              />
            )}
            <Polyline positions={route} color="blue" />
            <ZoomControl position="topright" />
            {weatherSuggestion && (
              <WeatherSuggestionContainer>
                {weatherSuggestion}
              </WeatherSuggestionContainer>
            )}
            {showPopularRoutes && popularRoutes && popularRoutes.length > 0 && (
              <PopularRoutes routes={popularRoutes} />
            )}
            {showDistanceMarkers && visibleMarkers.map((marker, index) => (
              <Marker 
                key={index} 
                position={marker.position} 
                icon={distanceIcon(marker.distance)}
              >
                <Popup>{marker.distance} {isKm ? 'km' : 'mi'}</Popup>
              </Marker>
            ))}
            {showWaypoints && waypoints.map((waypoint, index) => (
              <Marker 
                key={`waypoint-${index}`} 
                position={waypoint} 
                icon={index === 0 ? new L.Icon.Default() : waypointIcon(index + 1)}
                draggable={true}
                eventHandlers={{
                  dragend: (e) => {
                    const marker = e.target;
                    const position = marker.getLatLng();
                    // Update the waypoint position in your state
                    const newWaypoints = [...waypoints];
                    newWaypoints[index] = position;
                    setWaypoints(newWaypoints);
                  },
                }}
              >
                <Popup>Waypoint {index + 1}</Popup>
              </Marker>
            ))}
          </StyledMapContainer>
        )}
        {elevationData.length > 0 && showElevation && (
          <ElevationProfileContainer>
            <ElevationProfile elevationData={elevationData} />
            <div>Total Elevation Gain: {totalElevationGain.toFixed(2)} m</div>
            {estimatedPace && <div>Estimated Pace: {estimatedPace} min/km</div>}
          </ElevationProfileContainer>
        )}
      </ContentContainer>
    </PageContainer>
  );
};

export default MapComponent;
