import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';

// Styled components for consistent styling
const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f4f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h1`
  text-align: center;
  color: #264653;
  font-size: 2.5rem;
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
`;

const InfoSection = styled.section`
  margin-bottom: 30px;
  font-size: 1.1rem;
  color: #495057;
  line-height: 1.6;
  font-family: 'Roboto', sans-serif;
  text-align: center;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

const InputGroup = styled.div`
  width: 48%;
  margin-bottom: 10px;
`;

const Label = styled.label`
  display: block;
  font-size: 1rem;
  color: #495057;
  margin-bottom: 5px;
  font-family: 'Roboto', sans-serif;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #2a9d8f;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #2a9d8f;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 15px;
  background-color: #2a9d8f;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1.25rem;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #21867a;
  }
`;

const ResultsContainer = styled.section`
  margin-top: 20px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Result = styled.p`
  font-size: 1.2rem;
  color: #495057;
  font-family: 'Roboto', sans-serif;
`;

// Helper function to convert decimal minutes to mm:ss format
const convertToMinutesAndSeconds = (decimalMinutes: number): string => {
  let minutes = Math.floor(decimalMinutes);
  let seconds = Math.round((decimalMinutes - minutes) * 60);

  // If seconds round up to 60, adjust the minutes and seconds
  if (seconds === 60) {
    minutes += 1;
    seconds = 0;
  }

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds} min/mile`;
};

// Map race types to distances
const raceDistances: { [key: string]: number } = {
  '5K': 3.1,
  '10K': 6.2,
  'Half Marathon': 13.1,
  'Marathon': 26.2,
};

interface Paces {
  easyPace: string;
  marathonPace: string;
  thresholdPace: string;
  intervalPace: string;
  repetitionPace: string;
}

const TrainingPaceCalculator: React.FC = () => {
  const [raceType, setRaceType] = useState<string>('5K');
  const [time, setTime] = useState<string>('');
  const [paces, setPaces] = useState<Paces | null>(null);

  function predictMarathonTime(currentTime: number, currentDistance: number, targetDistance: number = 26.2, exponent: number = 1.06): number {
    const paceMultiplier = targetDistance / currentDistance;
    const predictedTime = currentTime * Math.pow(paceMultiplier, exponent);
    return predictedTime;
  }

  const calculatePaces = () => {
    const timeParts = time.split(':').map(Number);
    const totalMinutes = timeParts[0] * 60 + timeParts[1];
    const selectedDistance = raceDistances[raceType];
    let marathonTime = totalMinutes;
    if (selectedDistance < 26.2) {
      marathonTime = predictMarathonTime(totalMinutes, selectedDistance);
    }
    const pacePerMile = marathonTime / 26.2;

    const calculatedPaces: Paces = {
      easyPace: convertToMinutesAndSeconds(pacePerMile * 1.2),      // Typically 20% slower than race pace
      marathonPace: convertToMinutesAndSeconds(pacePerMile),         // Marathon race pace
      thresholdPace: convertToMinutesAndSeconds(pacePerMile * 0.94),  // Approximately 10% faster
      intervalPace: convertToMinutesAndSeconds(pacePerMile * 0.86),  // Typically 15% faster
      repetitionPace: convertToMinutesAndSeconds(pacePerMile * 0.81), // Typically 20% faster
    };

    setPaces(calculatedPaces);
  };

  return (
    <>
      <Helmet>
        <title>Training Paces Calculator - Optimize Your Running Performance</title>
        <meta 
          name="description" 
          content="Calculate your optimal training paces for different types of runs, including easy, marathon, threshold, interval, and repetition paces. Improve your running performance with our pace calculator." 
        />
        <meta 
          name="keywords" 
          content="running pace calculator, marathon training paces calculator, pace calculator, running pace, training pace, running performance, easy pace, marathon pace, interval pace, threshold pace, repetition pace, running tips" 
        />
        <link rel="canonical" href="https://airunningtraining.com/trainingPaceCalculator" />
        <script type="application/ld+json">
           {`
             {
               "@context": "http://schema.org",
               "@type": "WebPage",
               "name": "Training Paces Calculator",
               "description": "Calculate your optimal running paces for different race distances. Improve your running performance with our easy-to-use pace calculator.",
               "url": "https://airunningtraining.com/trainingPaceCalculator"
             }
           `}
         </script>
      </Helmet>
      <Container>
        <Header>Training Paces Calculator</Header>
        <InfoSection>
          <p>
            Welcome to our Training Paces Calculator! This tool is designed to help runners of all levels optimize their training by calculating the ideal paces for different types of runs. Whether you're preparing for a 5K, 10K, Half Marathon, or Marathon, knowing your target paces can make your training more effective and enjoyable.
          </p>
          <p>
            By simply entering your recent race time and selecting your race distance, our calculator provides you with easy, marathon, threshold, interval, and repetition paces tailored to your current fitness level. Use these paces to structure your workouts, ensuring you're training at the right intensity to achieve your goals.
          </p>
        </InfoSection>
        <InputContainer>
          <InputGroup>
            <Label htmlFor="raceType">Race Distance</Label>
            <Select
              id="raceType"
              value={raceType}
              onChange={(e) => setRaceType(e.target.value)}
            >
              <option value="5K">5K</option>
              <option value="10K">10K</option>
              <option value="Half Marathon">Half Marathon</option>
              <option value="Marathon">Marathon</option>
            </Select>
          </InputGroup>
          <InputGroup>
            <Label htmlFor="time">Race Time (hh:mm)</Label>
            <Input
              type="text"
              id="time"
              placeholder="Enter time as hh:mm"
              value={time}
              onChange={(e) => setTime(e.target.value)}
            />
          </InputGroup>
        </InputContainer>
        <Button onClick={calculatePaces}>Calculate Paces</Button>
        {paces && (
          <ResultsContainer>
            <h2>Calculated Paces</h2>
            <Result><strong>Easy Pace:</strong> {paces.easyPace}</Result>
            <Result><strong>Marathon Pace:</strong> {paces.marathonPace}</Result>
            <Result><strong>Threshold Pace:</strong> {paces.thresholdPace}</Result>
            <Result><strong>Interval Pace:</strong> {paces.intervalPace}</Result>
            <Result><strong>Repetition Pace:</strong> {paces.repetitionPace}</Result>
          </ResultsContainer>
        )}
      </Container>
    </>
  );
};

export default TrainingPaceCalculator;
