import * as React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

interface ElevationPoint {
  elevation: number;
  distance: number;
}

interface ElevationProfileProps {
  elevationData: ElevationPoint[];
}

const ElevationProfile: React.FC<ElevationProfileProps> = ({ elevationData }) => (
  <ResponsiveContainer width="100%" height="100%">
    <LineChart data={elevationData} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
      <XAxis 
        dataKey="distance" 
        label={{ value: 'Distance (km)', position: 'insideBottomRight', offset: -10 }}
        tick={{ fontSize: 12 }}
      />
      <YAxis 
        label={{ value: 'Elevation (m)', angle: -90, position: 'insideLeft', offset: 10 }}
        tick={{ fontSize: 12 }}
      />
      <Tooltip 
        contentStyle={{ fontSize: 12 }}
        labelStyle={{ fontWeight: 'bold' }}
        formatter={(value: number) => [`${value.toFixed(1)} m`, 'Elevation']}
        labelFormatter={(label: number) => `Distance: ${label.toFixed(2)} km`}
      />
      <Line type="monotone" dataKey="elevation" stroke="#8884d8" dot={false} />
    </LineChart>
  </ResponsiveContainer>
);

export default ElevationProfile;
