import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import styled from '@emotion/styled';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Card, CardHeader, CardContent, CardActions, Button } from './StyledComponents';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
`;

const Box = styled.div`
  margin-top: ${props => props.mt || 0}px;
  margin-bottom: ${props => props.mb || 0}px;
  padding: ${props => props.p || 0}px;
  display: ${props => props.display || 'block'};
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || 0}px;
`;

const DayCard = styled.div`
  border-bottom: 1px solid #eee;
  padding: 16px;
  background-color: ${props => props.completed ? '#e8f5e9' : 'transparent'};
  border-radius: 4px;
  margin-bottom: 8px;
  transition: background-color 0.3s ease;
  &:last-child {
    border-bottom: none;
  }
`;

const DayTitle = styled.h3`
  margin: 0 0 8px 0;
`;

const Workout = styled.p`
  margin: 0 0 8px 0;
`;

const Checkbox = styled.input`
  margin-right: 8px;
`;

const TrainingProgress = () => {
  const { user } = useAuth();
  const [currentWeek, setCurrentWeek] = useState(null);
  const [progress, setProgress] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function fetchWeeklyTrainingData() {
      try {
        if (location.state && location.state.weekData && Array.isArray(location.state.weekData)) {
          const weekNumber = location.state.weekData[0].Week;
          setCurrentWeek({
            week: `Week ${weekNumber}`,
            days: location.state.weekData.map(day => ({
              day: day.Day,
              workout: `${day['Workout Type']} - ${day['Distance (miles)']} miles at ${day['Pace (min/mile)']} pace. ${day.Notes}`
            }))
          });
          
          // Initialize progress state
          const initialProgress = {};
          location.state.weekData.forEach(day => {
            initialProgress[day.Day] = false;
          });
          setProgress(initialProgress);
        } else {
          // Fallback to dummy data
          setCurrentWeek(dummyTrainingPlan[0]);
          
          // Initialize progress state with dummy data
          const initialProgress = {};
          dummyTrainingPlan[0].days.forEach(day => {
            initialProgress[day.day] = false;
          });
          setProgress(initialProgress);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
    fetchWeeklyTrainingData();
  }, [location.state]);

  const handleProgressUpdate = (day, completed) => {
    setProgress(prevProgress => ({
      ...prevProgress,
      [day]: completed
    }));
  };

  const handleSaveProgress = () => {
    // Here you would typically send the progress data to your backend
    console.log('Saving progress:', progress);
    // You can add an API call here to save the progress
  };

  const handleDayClick = (day) => {
    const formattedWeek = `week-${currentWeek.week.split(' ')[1]}`;
    navigate(`/training-progress/${formattedWeek}/${day.day}`, {
      state: { workout: day }
    });
  };

  return (
    <Container>
      <Box mt={32} mb={32}>
        <Card>
          <CardHeader>
            <Typography as="h1">{currentWeek?.week || 'Current Week'} Training</Typography>
          </CardHeader>
          <CardContent>
            {currentWeek?.days.map((day) => (
              <DayCard 
                key={day.day} 
                completed={progress[day.day]}
                onClick={() => handleDayClick(day)}
                style={{ cursor: 'pointer' }}
              >
                <DayTitle>{day.day}</DayTitle>
                <Workout>{day.workout}</Workout>
                <label onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    type="checkbox"
                    checked={progress[day.day] || false}
                    onChange={(e) => handleProgressUpdate(day.day, e.target.checked)}
                  />
                  Completed
                </label>
              </DayCard>
            ))}
          </CardContent>
          <CardActions>
            <Button onClick={handleSaveProgress}>Save Progress</Button>
          </CardActions>
        </Card>
      </Box>
    </Container>
  );
};

// Dummy data for demonstration purposes
const dummyTrainingPlan = [
  {
    week: 'Dummy Week 1',
    days: [
      { day: 'Monday', workout: '5 miles easy' },
      { day: 'Tuesday', workout: '4 miles easy + 6 strides' },
      { day: 'Wednesday', workout: '6 miles with 3 miles at half-marathon pace' },
      { day: 'Thursday', workout: 'Rest or cross-train' },
      { day: 'Friday', workout: '5 miles easy' },
      { day: 'Saturday', workout: '8 miles long run' },
      { day: 'Sunday', workout: 'Rest' },
    ],
  },
  // Add more weeks as needed
];

const dummyProgress = {
  'Week 1': {
    'Monday': 'Completed 5 miles',
    'Tuesday': 'Did 4 miles and 6 strides',
    // ... other days
  },
  // ... other weeks
};

export default TrainingProgress;
