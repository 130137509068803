import React from 'react';
import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { FaCheckCircle, FaMountain, FaMap, FaCloud } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';


// Styled Components
const Container = styled.div`
  padding: 50px 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const Header = styled.header`
  margin-bottom: 40px;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #2a9d8f;
  margin-bottom: 10px;
`;

const SubTitle = styled.p`
  font-size: 1.5rem;
  color: #264653;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin: 50px 0;
`;

const FeatureCard = styled.div`
  background-color: #ffffff;
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
  }
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  color: #2a9d8f;
  margin-bottom: 20px;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  color: #264653;
  margin-bottom: 15px;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: #555555;
`;

const PricingSection = styled.section`
  background-color: #e9f5f4;
  padding: 50px 20px;
  border-radius: 10px;
`;

const PricingTitle = styled.h2`
  font-size: 2rem;
  color: #2a9d8f;
  margin-bottom: 30px;
`;

const PricingPlans = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
`;

const PlanCard = styled.div<{ mostPopular?: boolean }>`
  background-color: #ffffff;
  padding: 30px 20px;
  border: ${({ mostPopular }) => (mostPopular ? '2px solid #2a9d8f' : '1px solid #ddd')};
  border-radius: 10px;
  width: 300px;
  position: relative;
  
  ${({ mostPopular }) =>
    mostPopular &&
    `
    box-shadow: 0 6px 10px rgba(42, 157, 143, 0.2);
  `}
`;

const PlanTitle = styled.h3`
  font-size: 1.5rem;
  color: #264653;
  margin-bottom: 20px;
`;

const PlanPrice = styled.p`
  font-size: 2rem;
  color: #e76f51;
  margin-bottom: 20px;
`;

const PlanFeatures = styled.ul`
  list-style: none;
  padding: 0;
  text-align: left;
  margin-bottom: 30px;
`;

const PlanFeature = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #555555;

  svg {
    margin-right: 10px;
    color: #2a9d8f;
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  padding: 12px 30px;
  background-color: #2a9d8f;
  color: #ffffff;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer; /* Add this line */
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #21867a;
  }
`;

const FAQSection = styled.section`
  margin-top: 50px;
  text-align: left;
`;

const FAQTitle = styled.h2`
  font-size: 2rem;
  color: #2a9d8f;
  margin-bottom: 30px;
`;

const FAQItem = styled.div`
  margin-bottom: 20px;
`;

const FAQQuestion = styled.h4`
  font-size: 1.2rem;
  color: #264653;
  margin-bottom: 10px;
`;

const FAQAnswer = styled.p`
  font-size: 1rem;
  color: #555555;
`;

const Footer = styled.footer`
  margin-top: 50px;
  padding: 20px;
  background-color: #f0f2f5;
  border-radius: 10px;
`;

const FooterText = styled.p`
  font-size: 0.9rem;
  color: #555555;
`;

// Component
const PremiumFeatures: React.FC = () => {
    const { user, isPremium } = useAuth();
    const navigate = useNavigate();

    const handleSubscribe = async () => {
        if (!user) {
          alert('You must be signed in to subscribe.');
          navigate('/login');
          return;
        }
        try {
          const response = await fetch('https://c3xzkliim3.execute-api.eu-north-1.amazonaws.com/stripepaymentrn', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: user.email || '',
              env: process.env.REACT_APP_ENV || '',
            }),
          });
    
          if (!response.ok) {
            throw new Error('Failed to create checkout session');
          }
    
          const { checkoutUrl, sessionId, customerId } = await response.json();
    
          if (!checkoutUrl) {
            throw new Error('No redirect URL received from the server');
          }
    
          // Store sessionId and customerId in localStorage for later use
          localStorage.setItem('stripeSessionId', sessionId);
          localStorage.setItem('stripeCustomerId', customerId);
    
          // Redirect to the Stripe Checkout page
          window.location.href = checkoutUrl;
    
          // After successful subscription, update the premium status
        //   await checkPremiumStatus();
        } catch (error) {
          console.error('Subscription error:', error);
          // Handle the error (e.g., show an error message to the user)
          alert('An error occurred while processing your subscription. Please try again.');
        }
      };
  return (
    <>
      <Helmet>
        <title>Running Route Planner Premium Features | AI Running Training</title>
        <meta 
          name="description" 
          content="Upgrade to Premium and access exclusive features designed to enhance your running route planning experience. Enjoy personalized Popular Routes, Route Elevation, Offline Maps, and Weather Suggestions."
        />
      </Helmet>
      <Container>
        <Header>
          <Title>Unlock Premium Features</Title>
          <SubTitle>Enhance Your Running Experience with Our Exclusive Tools</SubTitle>
        </Header>

        <FeaturesGrid>
          <FeatureCard>
            <FeatureIcon>
              <FaMap />
            </FeatureIcon>
            <FeatureTitle>Offline Maps</FeatureTitle>
            <FeatureDescription>
            Stay on course, no matter where your runs take you. With our Offline Maps feature, access route information without the need for an internet connection. Perfect for running in places with limited connectivity.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureIcon>
              <FaMountain />
            </FeatureIcon>
            <FeatureTitle>Route Elevation</FeatureTitle>
            <FeatureDescription>
            Elevation data is crucial for runners, especially those who train on hills or those who want to improve their performance. Our Route Elevation feature provides detailed elevation data for every route, helping you train smarter and safer.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureIcon>
              <FaCloud />
            </FeatureIcon>
            <FeatureTitle>Weather Suggestions</FeatureTitle>
            <FeatureDescription>
              Weather can significantly impact your running experience. Our Weather Suggestions feature provides you with suggestions for when to run, helping you stay safe and make the most of your runs.
            </FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>

        <PricingSection>
          <PricingTitle>Choose Your Plan</PricingTitle>
          <PricingPlans>
            <PlanCard>
              <PlanTitle>Basic</PlanTitle>
              <PlanPrice>FREE</PlanPrice>
              <PlanFeatures>
                <PlanFeature><FaCheckCircle /> Free Draw Route</PlanFeature>
                <PlanFeature><FaCheckCircle /> Auto Round Trip Routes</PlanFeature>
                <PlanFeature><FaCheckCircle /> Save Routes Publicly</PlanFeature>
                <PlanFeature><FaCheckCircle /> Download Routes</PlanFeature>
                <PlanFeature><FaCheckCircle /> Upload Routes</PlanFeature>
              </PlanFeatures>
              {user ? <CTAButton href="/running-route-planner">Current Plan</CTAButton> : <CTAButton href="/login">Sign Up</CTAButton>}
            </PlanCard>
            <PlanCard>
              <PlanTitle>Premium</PlanTitle>
              <PlanPrice>$14.99/year</PlanPrice>
              <PlanFeatures>
                <PlanFeature><FaCheckCircle /> Free Draw Route</PlanFeature>
                <PlanFeature><FaCheckCircle /> Auto Round Trip Routes</PlanFeature>
                <PlanFeature><FaCheckCircle /> Save Routes Publicly</PlanFeature>
                <PlanFeature><FaCheckCircle /> Download Routes</PlanFeature>
                <PlanFeature><FaCheckCircle /> Popular Routes</PlanFeature>
                <PlanFeature><FaCheckCircle /> Route Elevation</PlanFeature>
                <PlanFeature><FaCheckCircle /> Offline Maps</PlanFeature>
                <PlanFeature><FaCheckCircle /> Weather Suggestions</PlanFeature>
                <PlanFeature><FaCheckCircle /> No Ads</PlanFeature>
              </PlanFeatures>
              {user ? (
                isPremium ? (
                  <CTAButton onClick={handleSubscribe}>Current Plan</CTAButton>
                ) : (
                  <CTAButton onClick={handleSubscribe}>Upgrade to Premium</CTAButton>
                )
              ) : (
                <CTAButton href="/login">Sign Up to Upgrade</CTAButton>
              )}
            </PlanCard>

            {/* <PlanCard mostPopular>
              <span style={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                backgroundColor: '#e76f51',
                color: '#ffffff',
                padding: '5px 10px',
                borderRadius: '50px',
                fontSize: '0.8rem'
              }}>Most Popular</span>
              <PlanTitle>Premium</PlanTitle>
              <PlanPrice>$19.99/month</PlanPrice>
              <PlanFeatures>
                <PlanFeature><FaCheckCircle /> Personalized Training Plans</PlanFeature>
                <PlanFeature><FaCheckCircle /> Advanced Performance Analytics</PlanFeature>
                <PlanFeature><FaCheckCircle /> Exclusive Content & Tips</PlanFeature>
                <PlanFeature><FaCheckCircle /> Priority Support</PlanFeature>
                <PlanFeature><FaCheckCircle /> Community Access</PlanFeature>
              </PlanFeatures>
              <CTAButton href="/upgrade">Upgrade to Premium</CTAButton>
            </PlanCard> */}
          </PricingPlans>
        </PricingSection>

        <FAQSection>
          <FAQTitle>Frequently Asked Questions</FAQTitle>
          <FAQItem>
            <FAQQuestion>What is included in the Premium plan?</FAQQuestion>
            <FAQAnswer>
              The Premium plan offers personalized Popular Routes, Route Elevation, Offline Maps, and Weather Suggestions.
            </FAQAnswer>
          </FAQItem>
          <FAQItem>
            <FAQQuestion>Can I cancel my subscription anytime?</FAQQuestion>
            <FAQAnswer>
              Yes, you can cancel your subscription at any time. You will continue to have access to Premium features until the end of your current billing cycle.
            </FAQAnswer>
          </FAQItem>
          <FAQItem>
            <FAQQuestion>Is there a free trial available?</FAQQuestion>
            <FAQAnswer>
              We currently do not offer a free trial, but you can start with our Basic plan which provides essential features to kickstart your running route planner journey.
            </FAQAnswer>
          </FAQItem>
        </FAQSection>

        <Footer>
          <FooterText>&copy; {new Date().getFullYear()} AI Running Training. All rights reserved.</FooterText>
        </Footer>
      </Container>
    </>
  );
};

export default PremiumFeatures;
