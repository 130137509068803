/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

// Navbar styles
const navbarStyle = css`
  background-color: #264653;
  padding: 0.75rem 1rem;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 0.75rem;
  }
`;

const NavList = styled.ul<{ isOpen: boolean }>`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-left: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    background-color: #264653;
    position: absolute;
    top: 56px;
    left: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
`;


const NavItem = styled.li`
  margin: 0;
  position: relative;

  &:hover > div {
    display: block;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #264653;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;

  @media (max-width: 768px) {
    position: static;
    box-shadow: none;
    background-color: #1a323d;
  }
`;

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
  display: block;
  padding: 0.75rem 1rem;

  &:hover {
    color: #2a9d8f;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
`;

const LogoImage = styled.img`
  height: 40px;
  margin-right: 0.75rem;
`;

const LogoText = styled.span`
  font-size: 1.5rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const HamburgerIcon = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
    margin-right: 0.5rem;
  }

  div {
    width: 25px;
    height: 2px;
    background-color: #fff;
    margin: 4px 0;
    transition: 0.4s;
  }

  &.open div:nth-of-type(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  &.open div:nth-of-type(2) {
    opacity: 0;
  }

  &.open div:nth-of-type(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
`;

const Navbar: React.FC = () => {
  const { user, isPremium } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleRoutePlanner = () => {
    setIsOpen(false);
    window.location.href = '/running-route-planner';
  };

  return (
    <nav css={navbarStyle}>
      <HamburgerIcon onClick={toggleMenu} className={isOpen ? 'open' : ''}>
        <div />
        <div />
        <div />
      </HamburgerIcon>
      <Logo to="/">
        <LogoImage src='https://airunningtraining.com/airunningtraininglogo.png' alt="AI Running Training Logo" />
        <LogoText>AIRUNNINGTRAINING</LogoText>
      </Logo>
      <NavList isOpen={isOpen}>
        <NavItem>
          <NavLink to="/" onClick={() => setIsOpen(false)}>Home</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/running-route-planner" onClick={handleRoutePlanner}>Plan Route</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/saved-routes" onClick={() => setIsOpen(false)}>Explore Routes</NavLink>
        </NavItem>
        <NavItem className="nav-item">
          <NavLink to="/training-hub" onClick={() => setIsOpen(false)}>Training Hub</NavLink>
          <DropdownContent>
            <NavLink to="/training-hub/5k-training-plan" onClick={() => setIsOpen(false)}>5K Training Plan</NavLink>
            <NavLink to="/training-hub/10k-training-plan" onClick={() => setIsOpen(false)}>10K Training Plan</NavLink>
            <NavLink to="/training-hub/half-marathon-training-plan" onClick={() => setIsOpen(false)}>Half Marathon Training Plan</NavLink>
            <NavLink to="/training-hub/marathon-training-plan" onClick={() => setIsOpen(false)}>Marathon Training Plan</NavLink>
          </DropdownContent>
        </NavItem>
        <NavItem>
          <NavLink to="/calculators" onClick={() => setIsOpen(false)}>Tools</NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/blogs" onClick={() => setIsOpen(false)}>Blogs</NavLink>
        </NavItem>
        {!isPremium && <NavItem>
          <NavLink to="/premium-features" onClick={() => setIsOpen(false)}>Premium</NavLink>
        </NavItem>}
        <NavItem>
          <NavLink to="/login" onClick={() => setIsOpen(false)}>
            {user ? user.username : 'Login'}
          </NavLink>
        </NavItem>
      </NavList>
    </nav>
  );
};

export default Navbar;
