import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.offline';

// Extend the L.tileLayer type
declare module 'leaflet' {
  namespace tileLayer {
    function offline(urlTemplate: string, options?: L.TileLayerOptions): L.TileLayer;
  }
}

interface OfflineMapProps {
  children?: React.ReactNode;
  center: L.LatLngExpression;
  zoom: number;
}

const OfflineMap: React.FC<OfflineMapProps> = ({ children, center, zoom }) => {
  // Function to load offline tiles
  const offlineTileLayer = L.tileLayer.offline('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    subdomains: 'abc',
    minZoom: 13,
    maxZoom: 19,
  });

  return (
    <MapContainer center={center} zoom={zoom} style={{ height: '100%', width: '100%' }}>
      <TileLayer 
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution={offlineTileLayer.options.attribution}
        subdomains={offlineTileLayer.options.subdomains}
        minZoom={offlineTileLayer.options.minZoom}
        maxZoom={offlineTileLayer.options.maxZoom}
      />
      {children}
    </MapContainer>
  );
};

export default OfflineMap;