import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { loadGoogleAnalytics, loadGoogleAds } from '../utils/loadGoogleScripts';

const Banner = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #2c2c2c;
  color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Message = styled.p`
  margin: 0 0 10px 0;
  text-align: center;

  @media (min-width: 600px) {
    margin: 0;
    text-align: left;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button<{ accept?: boolean }>`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${({ accept }) => (accept ? '#4CAF50' : '#f44336')};
  color: white;
  font-size: 16px;

  &:hover {
    opacity: 0.8;
  }
`;

const ConsentBanner: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('userConsent');
    if (!consent) {
      setShowBanner(true);
    } else if (consent === 'accepted') {
      initializeGoogleServices();
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('userConsent', 'accepted');
    setShowBanner(false);
    initializeGoogleServices();
  };

  const handleDecline = () => {
    localStorage.setItem('userConsent', 'declined');
    setShowBanner(false);
    // Optionally, handle the decline case
  };

  const initializeGoogleServices = () => {
    loadGoogleAnalytics('G-DWLLRFX883');
    loadGoogleAds('ca-pub-9515093664031179');
  };

  if (!showBanner) return null;

  return (
    <Banner>
      <Message>
        We use cookies and similar technologies to enhance your experience, analyze site traffic, and for marketing purposes. By clicking "Accept," you consent to our use of these technologies as described in our <a href="/privacy-policy">Privacy Policy</a> and <a href="/terms-and-conditions">Terms and Conditions</a>.
      </Message>
      <ButtonGroup>
        <Button accept onClick={handleAccept}>
          Accept
        </Button>
        <Button onClick={handleDecline}>
          Decline
        </Button>
      </ButtonGroup>
    </Banner>
  );
};

export default ConsentBanner;