import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const RelatedPagesContainer = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const RelatedPagesHeader = styled.h2`
  font-size: 1.5rem;
  color: #264653;
  margin-bottom: 20px;
  text-align: center;
`;

const RelatedPageItem = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.3s ease;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  &:hover {
    background-color: #f0f4f8;
  }
`;

const RelatedPageTitle = styled.h3`
  font-size: 1rem;
  color: #2a9d8f;
  margin-bottom: 5px;
  transition: text-decoration 0.3s ease;

  &:hover {
    text-decoration: underline;
  }
`;

interface RelatedPage {
  title: string;
  path: string;
}

interface RelatedPagesProps {
  title: string;
  pages: RelatedPage[];
}

const RelatedPages: React.FC<RelatedPagesProps> = ({ title, pages }) => {
  return (
    <RelatedPagesContainer>
      <RelatedPagesHeader>{title}</RelatedPagesHeader>
      {pages.map((page, index) => (
        <RelatedPageItem key={index} to={page.path}>
          <RelatedPageTitle>{page.title}</RelatedPageTitle>
        </RelatedPageItem>
      ))}
    </RelatedPagesContainer>
  );
};

export default RelatedPages;
