import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ToggleSwitch from '../../../components/ToggleSwitch';
import { usePreferences } from '../../../contexts/Preferences';
import { useAuth } from '../../../contexts/AuthContext';
import RelatedBlogs from '../../../components/RelatedBlogs';
import RelatedPages from '../../../components/RelatedPages';

const Container = styled.div`
  margin: 0 auto;
  padding: 0 16px;
`;

interface BoxProps {
  mt?: number;
  mb?: number;
  p?: number;
  display?: string;
  flexDirection?: string;
  gap?: number;
}

const Box = styled.div<BoxProps>`
  margin-top: ${props => props.mt || 0}px;
  margin-bottom: ${props => props.mb || 0}px;
  padding: ${props => props.p || 0}px;
  display: ${props => props.display || 'block'};
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || 0}px;
`;

const Typography = styled.h1`
  text-align: center;
  margin-bottom: 16px;
`;

const Paper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
`;

const Form = styled.form``;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background-color: #ccc;
  }
`;

const RightSidebar = styled.div`
  width: 20%;
  position: sticky;
  top: 20px;
  height: fit-content;

  @media (max-width: 1200px) {
    width: 100%;
    order: 2;
  }
`;

// Add new container styling for the layout
const PageLayout = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: nowrap;
  max-width: 100%;
  position: relative;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const MainContent = styled.div`
  flex: 1;
  min-width: 0; // Prevents flex item from overflowing
  max-width: calc(60% - 64px);

  @media (max-width: 1200px) {
    max-width: 100%;
  }
`;

// Add new styled components for left sidebar
const LeftSidebar = styled.div`
  width: 20%;
  position: sticky;
  top: 20px;
  height: fit-content;

  @media (max-width: 1200px) {
    width: 100%;
    order: 2;
  }
`;

// Add this interface above the component
interface BlogPost {
  id: number;
  title: string;
  summary: string;
  date: string;
  slug: string;
  topic: string;
  tags: string[];
  image: string;
}

const AITrainingPlanGenerator = () => {
  const { user: authUser } = useAuth();
  const { preferences, updatePreferences, fetchPreferences } = usePreferences();
  const [duration, setDuration] = useState('');
  const [frequency, setFrequency] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [targetTime, setTargetTime] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');
  const [planId, setPlanId] = useState(null);
  const [isKm, setIsKm] = useState(preferences?.isKm ?? true);
  const [pollStatusInterval, setPollStatusInterval] = useState<NodeJS.Timeout | null>(null);
  const [blogPosts, setBlogPosts] = useState([]);
  
  const goal = '5k';
  const navigate = useNavigate();

  const loadingMessages = [
    "Analyzing your goals and preferences...",
    "Calculating optimal training intensities...",
    "Designing your personalized training schedule...",
    "Creating your training plan...",
    "This can take up to a minute...",
  ];

  useEffect(() => {
    if (authUser?.username) {
      fetchPreferences(authUser.username);
    }
  }, [authUser, fetchPreferences]);

  useEffect(() => {
    setIsKm(preferences?.isKm ?? true);
  }, [preferences]);

  const updateLoadingMessage = () => {
    let messageIndex = 0;
    const intervalId = setInterval(() => {
      setLoadingMessage(loadingMessages[messageIndex]);
      messageIndex = (messageIndex + 1) % loadingMessages.length;
    }, 3000);
    return intervalId;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const messageInterval = updateLoadingMessage();

    try {
      const formattedGoal = goal.toUpperCase();
      
      const stepFunctionResponse = await axios.post(`${process.env.REACT_APP_TRAINING_PLANS_API_BASE_URL}/training-plans-step-function`, {
        goal: formattedGoal,
        duration, frequency, targetTime, userId: authUser?.username, isKm,
      });
      const executionArn = stepFunctionResponse.data.executionArn;
      
      // Poll the status every 5 seconds
      const pollStatus = async () => {
        try {
          const statusResponse = await axios.get(`${process.env.REACT_APP_TRAINING_PLANS_API_BASE_URL}/check-execution-status?executionArn=${executionArn}`);
          if (statusResponse.data.status === 'SUCCEEDED' || statusResponse.data.status === 'FAILED') {
            if (pollStatusInterval) clearInterval(pollStatusInterval);
            clearInterval(messageInterval);
            
            if (statusResponse.data.status === 'SUCCEEDED') {
              const stepFunctionOutput = JSON.parse(statusResponse.data.output);
              const savePlanResult = JSON.parse(stepFunctionOutput.savePlanResult.body);
              const savedPlanId = savePlanResult.planId;
              setPlanId(savedPlanId);
            }
            setIsLoading(false);
          }
        } catch (error) {
          console.error('Error polling status:', error);
          if (pollStatusInterval) clearInterval(pollStatusInterval);
          clearInterval(messageInterval);
          setIsLoading(false);
        }
      };

      setPollStatusInterval(setInterval(pollStatus, 5000));
    } catch (error: any) {
      console.error('Error:', error.response ? error.response.data : error.message);
      clearInterval(messageInterval);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (planId) {
      navigate(`/view-training-plan/${planId}`);
    }
  }, [planId]);

  const handleDistanceUnitToggle = async () => {
    if (!authUser?.username) return;
    
    const newPreferences = {
      ...preferences,
      isKm: !isKm
    };
    
    try {
      await updatePreferences(authUser.username, newPreferences);
    } catch (error) {
      console.error('Error updating preferences:', error);
      // Revert the toggle if update fails
      setIsKm(preferences.isKm);
    }
  };

  const relatedPages = [
    { title: '10K Training Plan', path: '/training-hub/10k-training-plan' },
    { title: 'Half Marathon Training Plan', path: '/training-hub/half-marathon-training-plan' },
    { title: 'Marathon Training Plan', path: '/training-hub/marathon-training-plan' },
  ];

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await axios.get('https://d2em90gkaptml7.cloudfront.net/prod/getAllBlogs');
        const parsedData = JSON.parse(response.data.body);
        setBlogPosts(parsedData.blog_posts);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    fetchBlogPosts();
  }, []);

  return (
    <>
      <Helmet>
        <title>5K Training Plan Generator | Free Personalized Running Programs | AIRunningTraining</title>
        <meta 
          name="description" 
          content="Create your free personalized 5K training plan in minutes. AI-powered running schedules for beginners to advanced runners. Choose 4-16 week programs with 3-6 training days. Start your journey to 5K success today!" 
        />
        <meta 
          name="keywords" 
          content="5K training plan, couch to 5K, running program, 5K for beginners, 5K race training, personalized running plan, AI running coach, 5K workout schedule, free 5K plan" 
        />
        
        <meta property="og:title" content="5K Training Plan Generator | Free Personalized Running Programs" />
        <meta property="og:description" content="Create your free personalized 5K training plan in minutes. AI-powered running schedules customized to your goals, experience level, and availability." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/path-to-your-og-image.jpg" />
        <meta property="og:url" content={window.location.href} />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="5K Training Plan Generator | Free Personalized Running Programs" />
        <meta name="twitter:description" content="Create your free personalized 5K training plan in minutes. AI-powered running schedules for all levels." />
        {/* <meta name="twitter:image" content="/path-to-your-twitter-image.jpg" /> */}
        
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "SoftwareApplication",
            "name": "5K Training Plan Generator",
            "applicationCategory": "Sports Training",
            "offers": {
              "@type": "Offer",
              "price": "0",
              "priceCurrency": "USD"
            },
            "description": "AI-powered 5K training plan generator that creates personalized running programs.",
            "operatingSystem": "Web"
          })}
        </script>
        
        <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/5k-training-plan-generator`} />
        
        {/* Add FAQ Schema markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "How long does it take to train for a 5K?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "For beginners, it typically takes 8-12 weeks to prepare for a 5K. Experienced runners may need less time, while complete beginners might benefit from a longer training period."
                }
              },
              {
                "@type": "Question",
                "name": "How often should I run while training for a 5K?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Most training plans recommend 3-5 running days per week, with rest or cross-training days in between. Beginners should start with 3 days to allow proper recovery."
                }
              },
              {
                "@type": "Question",
                "name": "What pace should I run during 5k training?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Most of your training runs should be at a conversational pace where you can easily talk. Speed work and tempo runs will be faster, but these should only make up about 20% of your training."
                }
              },
              {
                "@type": "Question",
                "name": "Do I need to run the full 5K distance during training?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "No, but it can be beneficial to run the full distance (or even slightly longer) during training. This can build confidence and helps you understand how to pace yourself on race day."
                }
              },
              {
                "@type": "Question",
                "name": "What should I eat before a 5K run?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Eat a light meal rich in carbohydrates 2-3 hours before running. Good options include banana with peanut butter, toast, or oatmeal. Avoid heavy or new foods before race day."
                }
              },
              {
                "@type": "Question",
                "name": "How can I prevent running injuries when training for a 5K?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Follow these key principles: gradually increase mileage, wear proper running shoes, include rest days, practice good form, and listen to your body. Stop running if you experience sharp pain."
                }
              },
              {
                "@type": "Question",
                "name": "What is considered a good 5K time?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "A good time for a 5K depends on your age, gender, and fitness level. For most people, a time between 20-30 minutes is considered good for a 5K. However, elite runners can complete the distance in under 15 minutes. The average walker can expect to finish the 5K in 45-60 minutes."
                }
              }
            ]
          })}
        </script>
      </Helmet>
      <Container>
        <PageLayout>
          <LeftSidebar>
            <RelatedPages title="Training Plans" pages={relatedPages} />
          </LeftSidebar>

          <MainContent>
            <Box mt={32} mb={32}>
              <Typography as="h1">Free 5K Training Plan Generator | Personalized Running Programs</Typography>
              <Paper>
                <Box p={24}>
                  <Form onSubmit={handleSubmit}>
                    <Box display="flex" flexDirection="column" gap={16}>
                      
                      <Select
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        required
                      >
                        <option value="">Duration of Plan</option>
                        <option value="4 weeks">4 weeks</option>
                        <option value="8 weeks">8 weeks</option>
                        <option value="12 weeks">12 weeks</option>
                        <option value="16 weeks">16 weeks</option>
                      </Select>
                      
                      <Select
                        value={frequency}
                        onChange={(e) => setFrequency(e.target.value)}
                        required
                      >
                        <option value="">Training Days per Week</option>
                        <option value="3 days/week">3 days/week</option>
                        <option value="4 days/week">4 days/week</option>
                        <option value="5 days/week">5 days/week</option>
                        <option value="6 days/week">6 days/week</option>
                      </Select>

                      <Box display="flex" flexDirection="column" gap={16}>
                        <label htmlFor="targetTime">Target Finish Time</label>
                        <input
                          type="text"
                          id="targetTime"
                          placeholder="e.g., 30:00 for a 30 minute 5K"
                          value={targetTime}
                          onChange={(e) => setTargetTime(e.target.value)}
                          required
                        />
                      </Box>

                      <Box display="flex" flexDirection="column" gap={8}>
                        <label htmlFor="distanceMetric">Distance Metric</label>
                        <ToggleSwitch
                          id="distance-unit-toggle"
                          isChecked={isKm ?? false}
                          onToggle={handleDistanceUnitToggle} 
                          leftLabel="Miles"
                          rightLabel="Kilometers"
                        />
                      </Box>
                      
                      <Box>
                        <Button
                          type="submit"
                          disabled={isLoading || !authUser}
                        >
                          {isLoading ? 'Generating...' : 'Generate Plan'}
                        </Button>
                        
                        {!authUser && (
                          <Box mt={8} style={{ textAlign: 'center', color: '#666' }}>
                            Please <a href="/login">sign in</a> to generate a training plan
                          </Box>
                        )}
                        
                        {isLoading && (
                          <Box mt={16} style={{ textAlign: 'center' }}>
                            <div className="loading-spinner"></div>
                            <p style={{ marginTop: '12px' }}>{loadingMessage}</p>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Form>
                </Box>
              </Paper>

              <Box mb={24} mt={32}>
                <h2>How to Train for a 5K</h2>
                <Box mt={16}>
                  <h3>Getting Started with 5K Training</h3>
                  <p>Training for a 5K (3.1 miles) is an excellent way to begin your running journey. Whether you're a complete beginner or looking to improve your time, following a structured training plan is key to success.</p>
                  
                  <h3>Key Components of 5K Training</h3>
                  <ul>
                    <li><strong>Base Building:</strong> Start with a combination of walking and running to build endurance gradually</li>
                    <li><strong>Progressive Overload:</strong> Gradually increase your running duration and intensity week by week</li>
                    <li><strong>Rest and Recovery:</strong> Include rest days between training sessions to prevent injury and allow adaptation</li>
                    <li><strong>Cross-Training:</strong> Incorporate strength training and other activities to improve overall fitness</li>
                  </ul>

                  <h3>Types of 5k Training Runs</h3>
                  <ul>
                    <li><strong>Easy Runs:</strong> Comfortable-paced runs that build aerobic endurance</li>
                    <li><strong>Speed Work:</strong> Intervals and tempo runs to improve pace and running economy</li>
                    <li><strong>Long Runs:</strong> Weekly longer-distance runs to build endurance</li>
                  </ul>

                  <h3>Tips for 5k Success</h3>
                  <ul>
                    <li>Start with proper running shoes and comfortable clothing</li>
                    <li>Stay hydrated before, during, and after runs</li>
                    <li>Follow a structured training plan tailored to your fitness level</li>
                    <li>Listen to your body and adjust training as needed</li>
                    <li>Join a local running group or find a training partner for motivation</li>
                  </ul>

                  <h2>Nutrition for 5K Training</h2>
                  <p>Proper nutrition plays a vital role in your 5K training success. A well-balanced diet will fuel your runs and help with recovery.</p>

                  <h3>Daily Nutrition Guidelines</h3>
                  <ul>
                    <li><strong>Carbohydrates:</strong> 45-65% of daily calories - focus on whole grains, fruits, and vegetables</li>
                    <li><strong>Protein:</strong> 15-25% of daily calories - lean meats, fish, legumes, and dairy</li>
                    <li><strong>Healthy Fats:</strong> 20-35% of daily calories - nuts, avocados, olive oil</li>
                  </ul>

                  <h3>Pre-Run Nutrition</h3>
                  <ul>
                    <li>Eat 2-3 hours before running</li>
                    <li>Choose easily digestible carbohydrates</li>
                    <li>Avoid high-fiber and high-fat foods</li>
                    <li>Stay hydrated but don't overdrink</li>
                  </ul>

                  <h3>Post-Run Recovery</h3>
                  <ul>
                    <li>Consume protein and carbs within 30 minutes after running</li>
                    <li>Rehydrate with water or sports drink</li>
                    <li>Replace electrolytes lost through sweat</li>
                    <li>Consider a recovery snack like chocolate milk or a banana with protein</li>
                  </ul>

                  <h3>Race Day Nutrition Tips</h3>
                  <ul>
                    <li>Don't try new foods on race day</li>
                    <li>Stick to your usual pre-run meal</li>
                    <li>Hydrate well in the days leading up to the race</li>
                    <li>Consider energy gels or sports drinks only if you've trained with them</li>
                  </ul>

                  <h2>Everything You Need to Know About Training for a 5K</h2>
                  <Box mt={16}>
                    <h3>How long does it take to train for a 5K?</h3>
                    <p>For beginners, it typically takes 8-12 weeks to prepare for a 5K. Experienced runners may need less time, while complete beginners might benefit from a longer training period.</p>

                    <h3>How often should I run while training for a 5K?</h3>
                    <p>Most training plans recommend 3-5 running days per week, with rest or cross-training days in between. Beginners should start with 3 days to allow proper recovery.</p>

                    <h3>What pace should I run during 5k training?</h3>
                    <p>Most of your training runs should be at a conversational pace where you can easily talk. Speed work and tempo runs will be faster, but these should only make up about 20% of your training.</p>

                    <h3>Do I need to run the full 5K distance during training?</h3>
                    <p>No, but it can be beneficial to run the full distance (or even slightly longer) during training. This can build confidence and helps you understand how to pace yourself on race day.</p>

                    <h3>What should I eat before a 5K run?</h3>
                    <p>Eat a light meal rich in carbohydrates 2-3 hours before running. Good options include banana with peanut butter, toast, or oatmeal. Avoid heavy or new foods before race day.</p>

                    <h3>How can I prevent running injuries when training for a 5K?</h3>
                    <p>Follow these key principles: gradually increase mileage, wear proper running shoes, include rest days, practice good form, and listen to your body. Stop running if you experience sharp pain.</p>

                    <h3>What is considered a good 5K time?</h3>
                    <p>A good time for a 5K depends on your age, gender, and fitness level. For most people, a time between 20-30 minutes is considered good for a 5K. However, elite runners can complete the distance in under 15 minutes. The average walker can expect to finish the 5K in 45-60 minutes.</p>
                  </Box>
                </Box>
              </Box>
            </Box>
          </MainContent>

          <RightSidebar>
            <RelatedBlogs 
              blogs={blogPosts as BlogPost[]}
              topic="Running Training"
              limit={3}
            />
          </RightSidebar>
        </PageLayout>
      </Container>
    </>
  );
};

export default AITrainingPlanGenerator;
