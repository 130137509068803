import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  padding: 20px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 6rem;
  margin: 0;
  color: #264653;
`;

const Subtitle = styled.h2`
  font-size: 2rem;
  margin: 20px 0;
  color: #2a9d8f;
`;

const Text = styled.p`
  font-size: 1.2rem;
  margin: 20px 0;
  color: #666;
`;

const HomeLink = styled(Link)`
  display: inline-block;
  padding: 12px 24px;
  background-color: #2a9d8f;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 20px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #264653;
  }
`;

const NotFound: React.FC = () => {
  const location = useLocation();
  const isBlogPath = location.state?.from === 'blogs';
  console.log(isBlogPath);
  console.log(window.location.pathname);

  return (
    <Container>
      <Helmet>
        <title>404 - Page Not Found | AI Running Training</title>
        <meta name="description" content="The page you're looking for doesn't exist. Return to AI Running Training home page." />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Title>404</Title>
      <Subtitle>Page Not Found</Subtitle>
      <Text>Sorry, the page you're looking for doesn't exist.</Text>
      <HomeLink to={isBlogPath ? "/blogs" : "/"}>
        {isBlogPath ? "Back to Blogs" : "Return to Home"}
      </HomeLink>
    </Container>
  );
};

export default NotFound;