import { useEffect } from 'react';
import { get } from '@aws-amplify/api-rest';
import { getCurrentUser } from 'aws-amplify/auth';

interface ApiKeyResponse {
    apiKey?: string;
  }

const fetchApiKey = async (): Promise<string> => {
  try {
    const user = await getCurrentUser();
    const restOperation = get({ 
        apiName: 'fetchApiKeyApi',
        path: '/apiKey' 
    });
    const response = await restOperation.response;
    const body = await response.body.json();
    const apiKeyResponse = body as ApiKeyResponse;

    const apiKey = apiKeyResponse.apiKey;

    return apiKey as string;
  } catch (error) {
    throw error;
  }
};

export default fetchApiKey;
