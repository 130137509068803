import React, { createContext, useState, useContext, useCallback, ReactNode } from 'react';
import axios from 'axios';

interface Preferences {
  isKm: boolean;
  // add other preferences here
}

interface PreferencesContextType {
  preferences: Preferences;
  isLoading: boolean;
  fetchPreferences: (userId: string) => Promise<void>;
  updatePreferences: (userId: string, newPreferences: Preferences) => Promise<void>;
}

const PreferencesContext = createContext<PreferencesContextType | undefined>(undefined);

interface PreferencesProviderProps {
  children: ReactNode;
}

export function PreferencesProvider({ children }: PreferencesProviderProps) {
  const [preferences, setPreferences] = useState<Preferences>({
    isKm: true,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);

  const fetchPreferences = useCallback(async (userId: string) => {
    if (initialized) return; // Prevent multiple fetches
    
    try {
      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/preferences`, {
        params: { userId },
      });
      const bodyData = JSON.parse(response.data.body);
      
      if (bodyData && bodyData.preferences) {
        setPreferences(bodyData.preferences);
      }
      setInitialized(true);
    } catch (error) {
      console.error('Error fetching preferences:', error);
    } finally {
      setIsLoading(false);
    }
  }, [initialized]);

  const updatePreferences = useCallback(async (userId: string, newPreferences: Preferences) => {
    try {
      setIsLoading(true);
      
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/preferences`, 
        JSON.stringify({ preferences: newPreferences, userId }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: userId,
          },
        }
      );
      setPreferences(newPreferences);
    } catch (error) {
      console.error('Error updating preferences:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <PreferencesContext.Provider 
      value={{ 
        preferences, 
        isLoading, 
        fetchPreferences, 
        updatePreferences 
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
}

export function usePreferences(): PreferencesContextType {
  const context = useContext(PreferencesContext);
  if (context === undefined) {
    throw new Error('usePreferences must be used within a PreferencesProvider');
  }
  return context;
}