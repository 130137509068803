import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MapContainer, TileLayer, Polyline } from 'react-leaflet';
import axios from 'axios';
import { LatLngBounds, LatLngTuple } from 'leaflet';
import styled from '@emotion/styled';

const Container = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 5px;
  box-sizing: border-box;

  @media (max-width: 800px) {
    max-width: 95%;
    width: 95vw;
    padding: 2px;
  }
`;

const MapWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 133.33%; // Changed to 3:4 aspect ratio for a taller, narrower view
  position: relative;
`;

const StyledMapContainer = styled(MapContainer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const FullScreenButton = styled.a`
  display: inline-block;
  padding: 8px 16px;
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-top: 8px;
  border-radius: 4px;

  &:hover {
    background-color: #0056b3;
  }
`;

const EmbedRoute: React.FC = () => {
  const { routeId } = useParams<{ routeId: string }>();
  const [route, setRoute] = useState<LatLngTuple[]>([]);
  const [fullScreenUrl, setFullScreenUrl] = useState<string>("");

  useEffect(() => {
    const fetchRoute = async () => {
      try {
        const response = await axios.get('https://dd1zrrqamr91n.cloudfront.net/prod/fetchRoute', {
          params: {
            routeId: routeId
          }
        });
        
        if (response.data.route) {
          console.log('response.data.route', response.data.route);
          setRoute(response.data.route);
          setFullScreenUrl(`${window.location.origin}/running-route-planner?loadRoute=${routeId}`);
        }
      } catch (error) {
        console.error('Error fetching route:', error);
      }
    };

    fetchRoute();
  }, [routeId]);

  if (route.length === 0) {
    return <Container>Loading route...</Container>;
  }

  const bounds = route.length > 1 
    ? new LatLngBounds(route as [number, number][]).pad(0.1) 
    : (route.length === 1 ? new LatLngBounds(route[0], route[0]).pad(0.1) : undefined);

  return (
    <Container>
      <MapWrapper>
        <StyledMapContainer 
          bounds={bounds ? [
            [bounds.getSouth(), bounds.getWest()],
            [bounds.getNorth(), bounds.getEast()]
          ] : undefined}
          center={route.length > 0 ? route[0] : undefined}
          zoom={bounds ? undefined : 12}
          zoomControl={false} // Disable zoom controls to save space
          attributionControl={false} // Remove attribution to save space
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Polyline positions={route} color="blue" />
        </StyledMapContainer>
      </MapWrapper>
      <div style={{ textAlign: 'center' }}>
        <FullScreenButton 
          href={fullScreenUrl} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          Full View
        </FullScreenButton>
      </div>
    </Container>
  );
};

export default EmbedRoute;