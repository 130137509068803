import styled from '@emotion/styled';

const Paper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
`;

export const Typography = styled.h1`
    text-align: center;
    margin-bottom: 16px;
`;

export const Card = styled(Paper)`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

export const CardHeader = styled.div`
  background-color: #f5f5f5;
  padding: 16px;
  border-bottom: 1px solid #ddd;
`;

export const CardContent = styled.div`
  padding: 16px;
`;

export const CardActions = styled.div`
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
`;

export const Button = styled.button`
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background-color: #ccc;
  }
`;