import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ToggleSwitch from '../../components/ToggleSwitch';
import { usePreferences } from '../../contexts/Preferences';
import { useAuth } from '../../contexts/AuthContext';

const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 0 16px;
`;

interface BoxProps {
  mt?: number;
  mb?: number;
  p?: number;
  display?: string;
  flexDirection?: string;
  gap?: number;
}

const Box = styled.div<BoxProps>`
  margin-top: ${props => props.mt || 0}px;
  margin-bottom: ${props => props.mb || 0}px;
  padding: ${props => props.p || 0}px;
  display: ${props => props.display || 'block'};
  flex-direction: ${props => props.flexDirection || 'row'};
  gap: ${props => props.gap || 0}px;
`;

const Typography = styled.h1`
  text-align: center;
  margin-bottom: 16px;
`;

const Paper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
`;

const Form = styled.form``;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background-color: #ccc;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 16px;
`;

const DayCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ErrorMessage = styled.div`
  color: #d32f2f;
  font-size: 0.875rem;
  margin-top: 4px;
`;

const AITrainingPlanGenerator = () => {
  const { user: authUser } = useAuth();
  const { preferences, updatePreferences, fetchPreferences } = usePreferences();
  const [goal, setGoal] = useState('');
  const [duration, setDuration] = useState('');
  const [frequency, setFrequency] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [targetTime, setTargetTime] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');
  const [planId, setPlanId] = useState(null);
  const [isKm, setIsKm] = useState(preferences?.isKm ?? true);
  const [pollStatusInterval, setPollStatusInterval] = useState<NodeJS.Timeout | null>(null);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [daySelectionError, setDaySelectionError] = useState<string>('');
  
  const navigate = useNavigate();

  const loadingMessages = [
    "Analyzing your goals and preferences...",
    "Calculating optimal training intensities...",
    "Designing your personalized training schedule...",
    "Creating your training plan...",
    "This can take up to a minute...",
  ];

  useEffect(() => {
    if (authUser?.username) {
      fetchPreferences(authUser.username);
    }
  }, [authUser, fetchPreferences]);

  useEffect(() => {
    setIsKm(preferences?.isKm ?? true);
  }, [preferences]);

  const updateLoadingMessage = () => {
    let messageIndex = 0;
    const intervalId = setInterval(() => {
      setLoadingMessage(loadingMessages[messageIndex]);
      messageIndex = (messageIndex + 1) % loadingMessages.length;
    }, 3000);
    return intervalId;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const messageInterval = updateLoadingMessage();

    try {
      const formattedGoal = goal.toUpperCase();

      console.log('selectedDays', selectedDays);
      
      const stepFunctionResponse = await axios.post(`${process.env.REACT_APP_TRAINING_PLANS_API_BASE_URL}/training-plans-step-function`, {
        goal: formattedGoal,
        duration, frequency, targetTime, userId: authUser?.username, isKm,
        trainingDays: selectedDays,
      });
      const executionArn = stepFunctionResponse.data.executionArn;
      
      // Poll the status every 5 seconds
      const pollStatus = async () => {
        try {
          const statusResponse = await axios.get(`${process.env.REACT_APP_TRAINING_PLANS_API_BASE_URL}/check-execution-status?executionArn=${executionArn}`);
          if (statusResponse.data.status === 'SUCCEEDED' || statusResponse.data.status === 'FAILED') {
            if (pollStatusInterval) clearInterval(pollStatusInterval);
            clearInterval(messageInterval);
            
            if (statusResponse.data.status === 'SUCCEEDED') {
              const stepFunctionOutput = JSON.parse(statusResponse.data.output);
              const savePlanResult = JSON.parse(stepFunctionOutput.savePlanResult.body);
              const savedPlanId = savePlanResult.planId;
              setPlanId(savedPlanId);
            }
            setIsLoading(false);
          }
        } catch (error) {
          console.error('Error polling status:', error);
          if (pollStatusInterval) clearInterval(pollStatusInterval);
          clearInterval(messageInterval);
          setIsLoading(false);
        }
      };

      setPollStatusInterval(setInterval(pollStatus, 5000));
    } catch (error: any) {
      console.error('Error:', error.response ? error.response.data : error.message);
      clearInterval(messageInterval);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (planId) {
      navigate(`/view-training-plan/${planId}`);
    }
  }, [planId]);

  const handleDistanceUnitToggle = async () => {
    if (!authUser?.username) return;
    
    const newPreferences = {
      ...preferences,
      isKm: !isKm
    };
    
    try {
      await updatePreferences(authUser.username, newPreferences);
    } catch (error) {
      console.error('Error updating preferences:', error);
      // Revert the toggle if update fails
      setIsKm(preferences.isKm);
    }
  };

  const getFrequencyNumber = (freq: string): number => {
    return parseInt(freq.split(' ')[0]) || 0;
  };

  const handleDayChange = (day: string) => {
    setSelectedDays(prev => {
      const freqNumber = getFrequencyNumber(frequency);
      if (prev.includes(day)) {
        setDaySelectionError('');
        return prev.filter(d => d !== day);
      } else {
        if (prev.length < freqNumber) {
          setDaySelectionError('');
          return [...prev, day];
        }
        setDaySelectionError(`You can only select ${freqNumber} training days per week based on your frequency selection.`);
        return prev;
      }
    });
  };

  const handleFrequencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFrequency = e.target.value;
    setFrequency(newFrequency);
    
    const freqNumber = getFrequencyNumber(newFrequency);
    if (selectedDays.length > freqNumber) {
      const newDays = selectedDays.slice(0, freqNumber);
      setSelectedDays(newDays);
      setDaySelectionError(`Training frequency reduced to ${freqNumber} days/week. Some selected days have been removed.`);
    } else {
      setDaySelectionError('');
    }
  };

  return (
    <>
      <Helmet>
        <title>AI Running Training Plan Generator |AIRunningTraining</title>
        <meta name="description" content="Generate a personalized running training plan for 5K, 10K, Half Marathon, or Marathon using AI. Get customized workouts based on your goals and schedule." />
        <meta name="keywords" content="running plan, training plan generator, AI running coach, marathon training, half marathon training, 5K training, 10K training" />
        <meta property="og:title" content="AI Running Training Plan Generator" />
        <meta property="og:description" content="Generate a personalized running training plan using AI. Custom plans for 5K to Marathon." />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Container>
        <Box mt={32} mb={32}>
          <Typography as="h1">Generate AI Training Plan</Typography>
          <Paper>
            <Box p={24}>
              <Form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column" gap={16}>
                  <Select
                    value={goal}
                    onChange={(e) => setGoal(e.target.value)}
                    required
                  >
                    <option value="">Select Event</option>
                    <option value="5k">5K</option>
                    <option value="10k">10K</option>
                    <option value="half marathon">Half Marathon</option>
                    <option value="marathon">Marathon</option>
                  </Select>
                  
                  <Select
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    required
                  >
                    <option value="">Duration of Plan</option>
                    <option value="4 weeks">4 weeks</option>
                    <option value="8 weeks">8 weeks</option>
                    <option value="12 weeks">12 weeks</option>
                    <option value="16 weeks">16 weeks</option>
                  </Select>
                  
                  <Select
                    value={frequency}
                    onChange={handleFrequencyChange}
                    required
                  >
                    <option value="">Training Days per Week</option>
                    <option value="3 days/week">3 days/week</option>
                    <option value="4 days/week">4 days/week</option>
                    <option value="5 days/week">5 days/week</option>
                    <option value="6 days/week">6 days/week</option>
                  </Select>

                  <Box display="flex" flexDirection="column" gap={16}>
                    <label htmlFor="targetTime">Target Finish Time</label>
                    <input
                      type="text"
                      id="targetTime"
                      placeholder="e.g., 3:30:00 for marathon"
                      value={targetTime}
                      onChange={(e) => setTargetTime(e.target.value)}
                      required
                    />
                  </Box>

                  <Box display="flex" flexDirection="column" gap={8}>
                    <label htmlFor="distanceMetric">Distance Metric</label>
                    <ToggleSwitch
                      id="distance-unit-toggle"
                      isChecked={isKm ?? false}
                      onToggle={handleDistanceUnitToggle} 
                      leftLabel="Miles"
                      rightLabel="Kilometers"
                    />
                  </Box>

                  <Box display="flex" flexDirection="column" gap={8}>
                    <label>Select Training Days</label>
                    <CheckboxGroup>
                      {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                        <DayCheckbox key={day}>
                          <input
                            type="checkbox"
                            id={day}
                            checked={selectedDays.includes(day)}
                            onChange={() => handleDayChange(day)}
                          />
                          <label htmlFor={day}>{day}</label>
                        </DayCheckbox>
                      ))}
                    </CheckboxGroup>
                    {daySelectionError && <ErrorMessage>{daySelectionError}</ErrorMessage>}
                  </Box>

                  <Box>
                    <Button
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? 'Generating...' : 'Generate Plan'}
                    </Button>
                    
                    {isLoading && (
                      <Box mt={16} style={{ textAlign: 'center' }}>
                        <div className="loading-spinner"></div>
                        <p style={{ marginTop: '12px' }}>{loadingMessage}</p>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Form>
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default AITrainingPlanGenerator;
