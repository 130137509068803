import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import L, { Polyline } from 'leaflet';
import html2canvas from 'html2canvas';
import { useAuth } from '../../contexts/AuthContext';
import { useSearchParams } from 'react-router-dom';
import { gpx } from '@tmcw/togeojson';
import { toast } from 'react-toastify'; // Optional: for notifications
import 'leaflet/dist/leaflet.css'; // Ensure Leaflet CSS is imported
import { FaFilter, FaRunning, FaFlag } from 'react-icons/fa';
import axios from 'axios';

const SavedRoutesContainer = styled.div`
  padding: 20px;
`;

const LoadingSpinner = styled.div`
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #2a9d8f;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 0 auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const RouteGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
`;

const RouteCard = styled(Link)`
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  display: block;
  position: relative;
`;

const ThumbnailContainer = styled.div`
  width: 100%;
  height: 150px;
  background-color: #f0f0f0;
`;

const RouteInfo = styled.div`
  padding: 10px;
`;

const RouteTitle = styled.h3`
  margin: 0 0 10px 0;
`;

const DeleteButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    background-color: #d32f2f;
    opacity: 1;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
`;

const FilterRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
`;

const FilterButton = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 15px;
  background-color: ${props => props.active ? '#4CAF50' : 'white'};
  color: ${props => props.active ? 'white' : '#333'};
  border: 1px solid #4CAF50;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: ${props => props.active ? 'bold' : 'normal'};

  &:hover {
    background-color: ${props => props.active ? '#45a049' : '#e8f5e9'};
  }
`;

const Input = styled.input`
  flex: 1;
  min-width: 200px;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
  }
`;

const FilterLabel = styled.span`
  font-weight: bold;
  margin-right: 10px;
`;

interface SavedRoute {
  routeId: string;
  routeName: string;
  username: string;
  userId: string;
  createdAt: string;
  city?: string;
  tags?: string[];
}

interface RouteWithGpx extends SavedRoute {
  gpxData?: string;
}

const SavedRoutes: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [savedRoutes, setSavedRoutes] = useState<RouteWithGpx[]>([]);
  const [thumbnails, setThumbnails] = useState<{ [key: string]: string }>({});
  const [filterCity, setFilterCity] = useState(searchParams.get('city') || '');
  const [filterType, setFilterType] = useState<'all' | 'my'>('all');
  const { user } = useAuth();
  const [showRaceRoutes, setShowRaceRoutes] = useState(false);
  const [thumbnailErrors, setThumbnailErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRoutes = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get('https://dd1zrrqamr91n.cloudfront.net/prod/fetchAllRoutes');
        
        const routesWithGpx: RouteWithGpx[] = response.data;
        setSavedRoutes(routesWithGpx);
        
        routesWithGpx.forEach(async (route) => {
          if (route.gpxData) {
            try {
              const thumbnail = await generateThumbnail(route.routeId, route.gpxData);
              setThumbnails(prev => ({ ...prev, [route.routeId]: thumbnail }));
            } catch (error) {
              console.error(`Error generating thumbnail for route ${route.routeId}:`, error);
              setThumbnailErrors(prev => ({ 
                ...prev, 
                [route.routeId]: error instanceof Error ? error.message : 'Unknown error'
              }));
            }
          } else {
            console.warn(`No GPX data for route ${route.routeId}`);
          }
        });
      } catch (error) {
        console.error("Error fetching routes:", error);
        toast.error("Failed to load routes. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchRoutes();
  }, []);

  useEffect(() => {
    if (filterCity) {
      setSearchParams({ city: filterCity });
    } else {
      searchParams.delete('city');
      setSearchParams(searchParams);
    }
  }, [filterCity, setSearchParams]);

  const generateThumbnail = async (routeId: string, gpxContent: string): Promise<string> => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(gpxContent, 'application/xml');
    const geoJson = gpx(xmlDoc) as GeoJSON.FeatureCollection;

    const trackPoints = geoJson.features.flatMap(feature => {
      if (feature.geometry.type === 'LineString') {
        return feature.geometry.coordinates.map(coord => [coord[1], coord[0]] as [number, number]);
      }
      return [];
    });

    const mapContainer = document.createElement('div');
    mapContainer.style.width = '250px';
    mapContainer.style.height = '150px';
    mapContainer.style.position = 'absolute';
    mapContainer.style.top = '-9999px'; // Hide the container
    document.body.appendChild(mapContainer);

    return new Promise<string>((resolve, reject) => {
      try {
        const map = L.map(mapContainer, {
          preferCanvas: true,
          attributionControl: false,
          zoomControl: false,
        });

        // Use Esri World Street Map tiles with CORS enabled
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; OpenStreetMap contributors',
          crossOrigin: 'anonymous', // Enable CORS
        }).addTo(map);

        let polyline: Polyline | null = null;

        if (trackPoints.length > 0) {
          polyline = L.polyline(trackPoints, {
            color: 'blue',
            weight: 2,
            opacity: 1,
          }).addTo(map);
          const bounds = polyline.getBounds();
          if (bounds.isValid()) {
            map.fitBounds(bounds);
          } else {
            map.setView([0, 0], 1);
          }
        } else {
          map.setView([0, 0], 1);
        }

        // Increase the delay to 1500ms to ensure tiles load
        setTimeout(() => {
          html2canvas(mapContainer, { useCORS: true }).then((canvas) => {
            const imgData = canvas.toDataURL();
            resolve(imgData);
            map.remove();
            document.body.removeChild(mapContainer);
          }).catch(reject);
        }, 1500); // Wait for tiles to load
      } catch (error) {
        reject(error);
      }
    });
  };

  const deleteRoute = async (routeId: string) => {
    if (!user?.username) {
      console.error("User not authenticated");
      return;
    }

    const routeToDelete = savedRoutes.find(route => route.routeId === routeId);
    if (!routeToDelete || routeToDelete.username !== user.username) {
      console.error("Unauthorized to delete this route");
      return;
    }

    try {
      await axios.delete('https://dd1zrrqamr91n.cloudfront.net/prod/deleteRoute', {
        params: {
          routeId: routeId
        }
      });

      // Update local state
      const updatedRoutes = savedRoutes.filter(route => route.routeId !== routeId);
      setSavedRoutes(updatedRoutes);

      const updatedThumbnails = { ...thumbnails };
      delete updatedThumbnails[routeId];
      setThumbnails(updatedThumbnails);

      toast.success(`Route "${routeToDelete.routeName}" deleted successfully!`);
    } catch (error) {
      console.error("Error deleting route:", error);
      toast.error("Failed to delete route. Please try again.");
    }
  };

  const filteredRoutes = savedRoutes.filter(route => {
    if (filterCity && !route.city?.toLowerCase().includes(filterCity.toLowerCase())) {
      return false;
    }

    if (filterType === 'my' && route.username !== user?.username) {
      return false;
    }

    if (showRaceRoutes && (!route.tags || !route.tags.includes('race'))) {
      return false;
    }

    return true;
  });

  useEffect(() => {
    const updateStructuredData = () => {
      const structuredData = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "name": filterCity ? `Running Routes in ${filterCity}` : "Running Routes",
        "description": filterCity ? `Discover the best running routes in ${filterCity}` : "Explore running routes from various locations",
        "itemListElement": filteredRoutes.map((route, index) => ({
          "@type": "ListItem",
          "position": index + 1,
          "item": {
            "@type": "SportsActivityLocation",
            "name": route.routeName,
            "description": `Running route in ${route.city || 'various locations'}`,
            "address": {
              "@type": "PostalAddress",
              "addressLocality": route.city
            },
            "url": `${window.location.origin}/running-route-planner?loadRoute=${encodeURIComponent(route.routeId)}`
          }
        }))
      };

      let script = document.querySelector('#structuredData');
      if (script) {
        script.textContent = JSON.stringify(structuredData);
      } else {
        script = document.createElement('script');
        script.id = 'structuredData';
        script.setAttribute('type', 'application/ld+json');
        script.textContent = JSON.stringify(structuredData);
        document.head.appendChild(script);
      }

      // Add canonical link
      let canonicalLink = document.querySelector('link[rel="canonical"]');
      const canonicalUrl = filterCity 
        ? `${window.location.origin}${window.location.pathname}?city=${encodeURIComponent(filterCity)}`
        : `${window.location.origin}${window.location.pathname}`;

      if (canonicalLink) {
        canonicalLink.setAttribute('href', canonicalUrl);
      } else {
        canonicalLink = document.createElement('link');
        canonicalLink.setAttribute('rel', 'canonical');
        canonicalLink.setAttribute('href', canonicalUrl);
        document.head.appendChild(canonicalLink);
      }
    };

    updateStructuredData();
  }, [filteredRoutes, filterCity]);

  return (
    <SavedRoutesContainer>
      <h1>Saved Routes</h1>
      <FilterContainer>
        <FilterRow>
          <FilterLabel>
            <FaFilter /> Filter:
          </FilterLabel>
          <FilterButton
            active={filterType === 'all'}
            onClick={() => setFilterType('all')}
          >
            <FaRunning /> All Routes
          </FilterButton>
          <FilterButton
            active={filterType === 'my'}
            onClick={() => setFilterType('my')}
          >
            <FaRunning /> My Routes
          </FilterButton>
          <FilterButton
            active={showRaceRoutes}
            onClick={() => setShowRaceRoutes(!showRaceRoutes)}
          >
            <FaFlag /> Race Routes
          </FilterButton>
        </FilterRow>
        <FilterRow>
          <FilterLabel>
            <FaFilter /> City:
          </FilterLabel>
          <Input
            type="text"
            value={filterCity}
            onChange={(e) => setFilterCity(e.target.value)}
            placeholder="Filter by city (e.g., London)"
          />
        </FilterRow>
      </FilterContainer>
      
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <RouteGrid>
          {filteredRoutes.map((route) => (
            <RouteCard key={route.routeId} to={`/running-route-planner?loadRoute=${encodeURIComponent(route.routeId)}`}>
              <ThumbnailContainer>
                {thumbnails[route.routeId] ? (
                  <img
                    src={thumbnails[route.routeId]}
                    alt={`Route: ${route.routeName}`}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                ) : (
                  <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#f0f0f0',
                    color: '#666'
                  }}>
                    <div style={{ textAlign: 'center' }}>
                      {thumbnailErrors[route.routeId] ? (
                        <>
                          <div style={{ marginBottom: '5px' }}>❌</div>
                          <small>Error loading map</small>
                        </>
                      ) : (
                        <>
                          <div style={{ marginBottom: '5px' }}>⌛</div>
                          Generating map...
                        </>
                      )}
                    </div>
                  </div>
                )}
              </ThumbnailContainer>
              <RouteInfo>
                <RouteTitle>{route.routeName}</RouteTitle>
                {route.city && <p>City: {route.city}</p>}
                <p>Created by: {route.username}</p>
                <p>Created at: {new Date(route.createdAt).toLocaleString()}</p>
              </RouteInfo>
              {route.username === user?.username && (
                <DeleteButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteRoute(route.routeId);
                  }}
                >
                  Delete
                </DeleteButton>
              )}
            </RouteCard>
          ))}
        </RouteGrid>
      )}
    </SavedRoutesContainer>
  );
};

export default SavedRoutes;