// Add this declaration at the top of the file
declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    dataLayer: any[]; // Add this line
  }
}

export const loadGoogleAnalytics = (id: string) => {
  const existingScript = document.getElementById('ga-script');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    script.id = 'ga-script';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function(...args: any[]) {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', id);
    };
  }
};

export const loadGoogleAds = (client: string) => {
  const existingScript = document.getElementById('adsbygoogle-script');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${client}`;
    script.setAttribute('crossorigin', 'anonymous');
    script.id = 'adsbygoogle-script';
    script.async = true;
    document.head.appendChild(script);
  }
};
