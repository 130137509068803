// src/components/ToggleSwitch.tsx
import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

interface ToggleSwitchProps {
  id: string;
  isChecked: boolean;
  onToggle: () => void;
  leftLabel: string;
  rightLabel: string;
  disabled?: boolean;
  isLoading?: boolean;
}

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  margin: 0 8px;
  font-size: 14px;
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;  // Adjusted width
  height: 24px; // Adjusted height
`;

const Slider = styled.span<{ isChecked: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ isChecked }) => (isChecked ? '#4caf50' : '#ccc')};
  transition: 0.4s;
  border-radius: 24px;

  &:before {
    position: absolute;
    content: '';
    height: 18px; // Adjusted height
    width: 18px;  // Adjusted width
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    transform: ${({ isChecked }) => (isChecked ? 'translateX(26px)' : 'translateX(0)')};
  }
`;

const HiddenCheckbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const LoadingWrapper = styled.div<{ isLoading: boolean }>`
  animation: ${({ isLoading }) => isLoading ? `${pulse} 1.5s ease-in-out infinite` : 'none'};
  opacity: ${({ isLoading }) => isLoading ? 0.7 : 1};
  cursor: ${({ isLoading }) => isLoading ? 'wait' : 'pointer'};
`;

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ 
  isChecked, 
  onToggle, 
  leftLabel, 
  rightLabel, 
  disabled,
  isLoading = false
}) => {
  return (
    <LoadingWrapper isLoading={isLoading}>
      <SwitchContainer>
        <Label>{leftLabel}</Label>
        <Switch>
          <HiddenCheckbox 
            type="checkbox" 
            checked={isChecked} 
            onChange={onToggle} 
            disabled={disabled || isLoading} 
          />
          <Slider isChecked={isChecked} />
        </Switch>
        <Label>{rightLabel}</Label>
      </SwitchContainer>
    </LoadingWrapper>
  );
};

export default ToggleSwitch;
