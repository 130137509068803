// Account.tsx
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ToggleSwitch from '../../components/ToggleSwitch';
import axios from 'axios';
import { usePreferences } from '../../contexts/Preferences';
// Styles
const ContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  padding: 20px;
`;

const AccountStyle = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const profileImageStyle = css`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
`;

const ButtonStyle = styled.button`
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1976d2;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  gap: 16px;
`;

const Account = () => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { preferences, isLoading, fetchPreferences, updatePreferences } = usePreferences();
  const [isUpdatingPreferences, setIsUpdatingPreferences] = useState(false);

  useEffect(() => {
    if (!user) return;
    
    const initPreferences = async () => {
      try {
        await fetchPreferences(user.username);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching preferences:', err);
        setError(true);
      }
    };

    initPreferences();
  }, [user, fetchPreferences]);

  const handleRetry = () => {
    setLoading(true);
    setError(false);
    window.location.reload(); // Refresh the browser
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleDistanceUnitToggle = async () => {
    if (!user) return;
    
    setIsUpdatingPreferences(true);
    try {
      await updatePreferences(user.username, {
        ...preferences,
        isKm: !preferences.isKm
      });
    } catch (err) {
      console.error('Error updating preferences:', err);
    } finally {
      setIsUpdatingPreferences(false);
    }
  };

  if (loading || isLoading && !isUpdatingPreferences) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <p>Loading...</p>
      </LoadingContainer>
    );
  }

  if (error) {
    return (
      <div>
        <p>Error loading user data. Please try again.</p>
        <ButtonStyle onClick={handleRetry}>Retry</ButtonStyle>
      </div>
    );
  }

  if (!user) {
    return <p>No user data available. Please log in again.</p>;
  }

  return (
    <ContainerStyle>
      <AccountStyle>
        <h2>My Account</h2>
        <p><strong>Username:</strong> {user.username}</p>
        <p><strong>Email:</strong> {user.email}</p>
        
        <h3>Preferences</h3>
        <label htmlFor="distanceMetric">Distance Metric:</label>
        <ToggleSwitch
          id="distance-unit-toggle"
          isChecked={preferences.isKm}
          onToggle={handleDistanceUnitToggle}
          leftLabel="Miles"
          rightLabel="Kilometers"
          disabled={isUpdatingPreferences}
          isLoading={isUpdatingPreferences}
        />

        <ButtonStyle onClick={handleSignOut}>
          Sign Out
        </ButtonStyle>
      </AccountStyle>
    </ContainerStyle>
  );
};

export default Account;
