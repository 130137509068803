/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import { FaTwitter, FaInstagram } from 'react-icons/fa';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9fafb;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h1`
  text-align: center;
  color: #264653;
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
`;

const SubHeader = styled.h2`
  color: #2a9d8f;
  font-size: 1.8rem;
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  color: #555555;
  line-height: 1.6;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const SocialLink = styled.a`
  color: #264653;
  font-size: 2rem;
  margin: 0 15px;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #e76f51;
  }
`;

const ContactPage: React.FC = () => {
  return (
    <Container>
      <Header>Contact Us</Header>
      <SubHeader>We'd Love to Hear From You!</SubHeader>
      <Paragraph>
        Whether you have a question about our training plans, feedback on the website, or just want to share your running journey, we're here to listen. Reach out to us through the following social media platforms:
      </Paragraph>
      <SocialIcons>
        <SocialLink href="https://twitter.com/runner_nathan36" target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </SocialLink>
        <SocialLink href="https://instagram.com/runner_nathan" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </SocialLink>
      </SocialIcons>
      {/* todo: Create email for website */}
      {/* <Paragraph>
        You can also send us an email at <a href="mailto:contact@yourwebsite.com">contact@yourwebsite.com</a>.
      </Paragraph> */}
    </Container>
  );
};

export default ContactPage;
