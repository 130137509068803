/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';

interface PaceRow {
  minutesPerKm: number;
  minutesPerMile: string;
  kmPerHour: string;
  milePerHour: string;
}

// Convert decimal minutes to a string format with minutes and seconds
const formatMinutesAndSeconds = (minutesDecimal: number): string => {
  const minutes = Math.floor(minutesDecimal);
  const seconds = Math.round((minutesDecimal - minutes) * 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

// Generate pace data starting at 8 km/h and incrementing by 0.1 km/h
const generatePaceData = (): PaceRow[] => {
  const paceData: PaceRow[] = [];
  let kmPerHour = 8.0;

  while (kmPerHour <= 15.0) { // Adjust the upper limit as needed
    const minutesPerKm = 60 / kmPerHour;
    const minutesPerMile = minutesPerKm * 1.60934;
    const milePerHour = 60 / minutesPerMile;

    paceData.push({
      minutesPerKm: parseFloat(minutesPerKm.toFixed(2)),
      minutesPerMile: formatMinutesAndSeconds(minutesPerMile),
      kmPerHour: kmPerHour.toFixed(2),
      milePerHour: milePerHour.toFixed(2),
    });

    kmPerHour += 0.1;
  }

  return paceData;
};

const Table = styled.table`
  width: 90%;
  margin: 20px auto;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Th = styled.th`
  background-color: #2a9d8f; /* Vibrant green-blue */
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: #333;
`;

const Tr = styled.tr`
  &:nth-of-type(even) {
    background-color: #f4f4f4;
  }
  &:hover {
    background-color: #e9f5f4;
  }
`;

const Header = styled.header`
  background-color: #264653; /* Darker shade of green-blue */
  color: #ffffff;
  padding: 20px 0;
  text-align: center;
  font-size: 2.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
`;

const Container = styled.div`
  background-color: #f0f4f8; /* Light background to contrast with table */
  min-height: 100vh;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
`;

const generateStructuredData = (paceData: PaceRow[]) => {
  return {
    "@context": "https://schema.org/",
    "@type": "Dataset",
    "name": "Running Pace Conversion Chart",
    "description": "A chart that converts running paces from kilometers per hour to miles per hour.",
    "url": "https://airunningtraining.com/paceConversionChart", // Replace with the actual URL of your page
    "creator": {
      "@type": "Organization",
      "name": "airunningtraining",
      "url": "https://airunningtraining.com" // Replace with your organization URL
    },
    "data": paceData.map((row) => ({
      "@type": "Table",
      "kmPerHour": row.kmPerHour,
      "minutesPerKm": row.minutesPerKm,
      "minutesPerMile": row.minutesPerMile,
      "milePerHour": row.milePerHour
    })),
  };
};

const PaceConversionChart: React.FC = () => {
  const paceData = generatePaceData();

  return (
    <Container>
      <Helmet>
        <title>Running Pace Conversion Chart | Km/h to Miles/h</title>
        <meta name="description" content="Easily convert running paces from kilometers per hour to miles per hour with this comprehensive pace conversion chart. Ideal for runners of all levels." />
        <meta name="keywords" content="running pace, pace conversion, km/h to miles/h, running chart, pace calculator" />
        <script type="application/ld+json">
          {JSON.stringify(generateStructuredData(paceData))}
        </script>
      </Helmet>
      <Header>
        🏃 Running Pace Conversion Chart {/* Adding a running emoji for consistency */}
      </Header>
      <Table>
        <caption>Running Pace Conversion from Km/h to Miles/h</caption>
        <thead>
          <Tr>
            <Th>Km per Hour</Th>
            <Th>Minutes per Km</Th>
            <Th>Minutes per Mile</Th>
            <Th>Miles per Hour</Th>
          </Tr>
        </thead>
        <tbody>
          {paceData.map((row, index) => (
            <Tr key={index}>
              <Td>{row.kmPerHour}</Td>
              <Td>{row.minutesPerKm}</Td>
              <Td>{row.minutesPerMile}</Td>
              <Td>{row.milePerHour}</Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default PaceConversionChart;
