import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import TemperatureHumidityImpact from '../blogPosts/TemperatureHumidityImpact';

// Styled components for consistent styling
const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f4f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h1`
  text-align: center;
  color: #264653;
  font-size: 2.5rem;
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

const InputGroup = styled.div`
  width: 48%;
  margin-bottom: 10px;
`;

const Label = styled.label`
  display: block;
  font-size: 1rem;
  color: #495057;
  margin-bottom: 5px;
  font-family: 'Roboto', sans-serif;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #2a9d8f;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #2a9d8f;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 15px;
  background-color: #2a9d8f;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1.25rem;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #21867a;
  }
`;

const ResultsContainer = styled.section`
  margin-top: 20px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Result = styled.p`
  font-size: 1.2rem;
  color: #495057;
  font-family: 'Roboto', sans-serif;
`;

const TimeInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const TimeInput = styled.input`
  width: 30%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #2a9d8f;
  }
`;

const convertToMinutesAndSeconds = (decimalMinutes: number): string => {
  let minutes = Math.floor(decimalMinutes);
  let seconds = Math.round((decimalMinutes - minutes) * 60);

  if (seconds === 60) {
    minutes += 1;
    seconds = 0;
  }

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

function adjustForConditions(
  raceTime: number,
  temperature: number,
  humidity: number
): number {
  const idealTemperature = 15;
  const idealHumidity = 40;

  let temperatureFactor = 0;
  if (temperature > idealTemperature) {
    temperatureFactor = (temperature - idealTemperature) * 0.005;
  } else if (temperature < idealTemperature) {
    temperatureFactor = (idealTemperature - temperature) * -0.005;
  }

  let humidityFactor = 0;
  if (humidity > idealHumidity) {
    humidityFactor = ((humidity - idealHumidity) / 10) * 0.005;
  } else if (humidity < idealHumidity) {
    humidityFactor = ((idealHumidity - humidity) / 10) * -0.005;
  }

  const totalFactor = 1 + temperatureFactor + humidityFactor;
  const adjustedTime = raceTime / totalFactor;

  return adjustedTime;
}

const PaceEquivalencyCalculator: React.FC = () => {
  const [raceDistance, setRaceDistance] = useState<string>('5K');
  const [hours, setHours] = useState<string>('');
  const [minutes, setMinutes] = useState<string>('');
  const [seconds, setSeconds] = useState<string>('');
  const [temperature, setTemperature] = useState<string>('');
  const [humidity, setHumidity] = useState<string>('');
  const [equivalentPace, setEquivalentPace] = useState<string | null>(null);

  const calculateEquivalentPace = () => {
    const totalMinutes = 
      parseInt(hours || '0', 10) * 60 + 
      parseInt(minutes || '0', 10) + 
      parseInt(seconds || '0', 10) / 60;

    const temp = parseFloat(temperature);
    const hum = parseFloat(humidity);

    const adjustedTime = adjustForConditions(totalMinutes, temp, hum);

    setEquivalentPace(convertToMinutesAndSeconds(adjustedTime));
  };

  return (
    <>
      <Helmet>
        <title>Running Temperature Equivalency Calculator - Adjust Your Running Pace for Conditions</title>
        <meta 
          name="description" 
          content="Calculate your equivalent running pace by adjusting for race conditions such as temperature, humidity. Optimize your training with accurate pace adjustments." 
        />
        <meta 
          name="keywords" 
          content="pace calculator, running pace, pace equivalency, running conditions, temperature adjustment, humidity, running performance" 
        />
        <link rel="canonical" href="https://airunningtraining.com/equivalencyCalculator" />
      </Helmet>
      <Container>
        <Header>Running Temperature Equivalency Calculator</Header>
        <p>
            Use this calculator to adjust your running pace for various race conditions, including temperature and humidity. Simply enter your race distance, recorded time, temperature, and humidity levels to see your equivalent race time under ideal conditions. This tool helps you understand how different environmental factors can impact your performance, allowing you to train and race more effectively.
        </p>

        <InputContainer>
          <InputGroup>
            <Label htmlFor="raceDistance">Race Distance</Label>
            <Select
              id="raceDistance"
              value={raceDistance}
              onChange={(e) => setRaceDistance(e.target.value)}
            >
              <option value="5K">5K</option>
              <option value="10K">10K</option>
              <option value="Half Marathon">Half Marathon</option>
              <option value="Marathon">Marathon</option>
            </Select>
          </InputGroup>
          <InputGroup>
            <Label>Race Time (hh:mm:ss)</Label>
            <TimeInputContainer>
              <TimeInput
                type="number"
                placeholder="hh"
                value={hours}
                onChange={(e) => setHours(e.target.value)}
              />
              <TimeInput
                type="number"
                placeholder="mm"
                value={minutes}
                onChange={(e) => setMinutes(e.target.value)}
              />
              <TimeInput
                type="number"
                placeholder="ss"
                value={seconds}
                onChange={(e) => setSeconds(e.target.value)}
              />
            </TimeInputContainer>
          </InputGroup>
          <InputGroup>
            <Label htmlFor="temperature">Temperature (°C)</Label>
            <Input
              type="number"
              id="temperature"
              placeholder="Enter temperature in °C"
              value={temperature}
              onChange={(e) => setTemperature(e.target.value)}
            />
          </InputGroup>
          <InputGroup>
            <Label htmlFor="humidity">Humidity (%)</Label>
            <Input
              type="number"
              id="humidity"
              placeholder="Enter humidity percentage"
              value={humidity}
              onChange={(e) => setHumidity(e.target.value)}
            />
          </InputGroup>
        </InputContainer>
        <Button onClick={calculateEquivalentPace}>Calculate Equivalent Race Time</Button>
        {equivalentPace && (
          <ResultsContainer>
            <h2>Equivalent Race Time</h2>
            <Result><strong>Time:</strong> {equivalentPace}</Result>
          </ResultsContainer>
        )}
        <TemperatureHumidityImpact />
      </Container>
    </>
  );
};

export default PaceEquivalencyCalculator;
