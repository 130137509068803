// PrivacyPolicy.tsx

import React from 'react';
import styled from '@emotion/styled';

// Styled Components using EmotionJS

const Container = styled.div`
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
`;

const Title = styled.h1`
    text-align: center;
    margin-bottom: 40px;
`;

const Section = styled.section`
    margin-bottom: 30px;
`;

interface SectionTitleProps {
    level?: number;
}

// Simplified SectionTitle component
const SectionTitle = styled.h2<SectionTitleProps>`
    font-size: ${({ level = 2 }) => (level === 3 ? '1.5em' : '2em')};
    margin-bottom: 10px;
    color: #333;
`;

const Paragraph = styled.p`
    font-size: 1em;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
`;

// New styled component for list items
const ListItem = styled.li`
    margin-bottom: 10px;
    color: #555;
`;

const PrivacyPolicy: React.FC = () => {
    return (
        <Container>
            <Title>Privacy Policy</Title>
            <Section>
                <SectionTitle>1. Introduction</SectionTitle>
                <Paragraph>
                    Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website{' '}
                    <a href="https://airunningtraining.com" target="_blank" rel="noopener noreferrer">
                        https://airunningtraining.com
                    </a>
                    , including any other media form, media channel, mobile website, or mobile application related or connected thereto.
                </Paragraph>
            </Section>

            <Section>
                <SectionTitle>2. Information We Collect</SectionTitle>
                <SectionTitle as="h3" level={3}>a. Personal Data</SectionTitle>
                <Paragraph>
                    We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, use our services, and in
                    connection with other activities, services, features, or resources we make available on our Site.
                </Paragraph>
                <ul>
                    <ListItem>Username</ListItem>
                    <ListItem>Email Address</ListItem>
                    <ListItem>Location Data</ListItem>
                </ul>

                <SectionTitle level={3}>b. Non-Personal Data</SectionTitle>
                <Paragraph>
                    We may also collect non-personal data, such as aggregated data or anonymized information, which cannot be used to identify you.
                </Paragraph>
            </Section>

            <Section>
                <SectionTitle>3. How We Use Your Information</SectionTitle>
                <ul>
                    <li>
                        <strong>To Provide and Improve Our Services:</strong> To personalize your experience, improve our website, and deliver relevant content.
                    </li>
                    <li>
                        <strong>To Communicate with You:</strong> To send you updates, newsletters, and other information that may be of interest to you.
                    </li>
                    <li>
                        <strong>For Marketing Purposes:</strong> To offer you promotions, special offers, and advertisements related to our services.
                    </li>
                    <li>
                        <strong>To Comply with Legal Obligations:</strong> To meet any applicable legal obligations, including complying with law enforcement requests or regulations.
                    </li>
                    <li>
                        <strong>For Security Purposes:</strong> To protect our website, systems, and users from fraud, unauthorized access, or other security risks.
                    </li>
                </ul>
            </Section>

            <Section>
                <SectionTitle>4. How We Share Your Information</SectionTitle>
                <Paragraph>
                    We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and
                    other parties who assist us in operating our website, conducting our business, or serving our users.
                </Paragraph>
                <ul>
                    <li>
                        <strong>Third-Party Services:</strong> We may share information with trusted third-party service providers to help us operate our website, conduct our business, or serve our users,
                        so long as those parties agree to keep this information confidential.
                    </li>
                    <li>
                        <strong>Compliance with Laws:</strong> We may disclose information where required to do so by law or in response to valid requests by public authorities.
                    </li>
                </ul>
            </Section>

            <Section>
                <SectionTitle>5. Third-Party Integrations</SectionTitle>
                <Paragraph>
                    Our website integrates with third-party services such as Strava, Google Analytics, and Google Ads. These services may collect information about you as described in their respective
                    privacy policies.
                </Paragraph>
            </Section>

            <Section>
                <SectionTitle>6. Your Consent</SectionTitle>
                <Paragraph>
                    By using our site, you consent to our Privacy Policy.
                </Paragraph>
            </Section>

            <Section>
                <SectionTitle>7. Changes to Our Privacy Policy</SectionTitle>
                <Paragraph>
                    We reserve the right to make changes to this Privacy Policy at any time. If we decide to change our privacy policy, we will post those changes on this page.
                </Paragraph>
            </Section>
        </Container>
    );
};

export default PrivacyPolicy;