import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { FaMountain, FaMap, FaCloud } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';

// Styled Components
const Container = styled.div`
  padding: 50px 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const Header = styled.header`
  margin-bottom: 40px;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #2a9d8f;
  margin-bottom: 10px;
`;

const SubTitle = styled.p`
  font-size: 1.5rem;
  color: #264653;
`;

const NavLink = styled.a`
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #2a9d8f;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #21867a;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin: 50px 0;
`;

const FeatureCard = styled.div`
  background-color: #ffffff;
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
  }
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  color: #2a9d8f;
  margin-bottom: 20px;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  color: #264653;
  margin-bottom: 15px;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: #555555;
`;

const Footer = styled.footer`
  margin-top: 50px;
  padding: 20px;
  background-color: #f0f2f5;
  border-radius: 10px;
`;

const FooterText = styled.p`
  font-size: 0.9rem;
  color: #555555;
`;

const ContentSection = styled.section`
  text-align: left;
  padding: 40px 0;
  line-height: 1.6;
  
  h2 {
    color: #264653;
    margin-bottom: 20px;
  }
  
  p {
    color: #555555;
    margin-bottom: 15px;
  }
`;

const ContentContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

// Component
const HomePage: React.FC = () => {

  return (
    <>
      <Helmet>
        <title>Running Route Planner & AI Training Plans | AI Running Training</title>
        <meta 
          name="description" 
          content="Create custom running routes and get AI-powered training plans. Plan round-trip runs, discover popular routes, export GPX files, and receive personalized marathon, 5K, or 10K training programs." 
        />
        <meta 
          name="keywords" 
          content="running route planner, running route maker, running route creator, running training plan, marathon training plan, 5K training plan, 10K training plan, running routes near me, GPX route creator, AI running coach" 
        />
        <meta property="og:title" content="Running Route Planner & AI Training Plans | AI Running Training" />
        <meta property="og:description" content="Create custom running routes and get AI-powered training plans. Plan round-trip runs, discover popular routes, and receive personalized training programs." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://airunningtraining.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Running Route Planner & AI Training Plans | AI Running Training" />
        <meta name="twitter:description" content="Create custom running routes and get AI-powered training plans. Perfect for beginners to advanced runners." />
        <link rel="canonical" href="https://airunningtraining.com/" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebApplication",
              "name": "AI Running Training",
              "description": "Running route planner and AI training plan generator for runners of all levels",
              "applicationCategory": "Sports & Fitness",
              "offers": {
                "@type": "Offer",
                "category": "Running Tools"
              },
              "featureList": [
                "Custom running route creation",
                "Round-trip route generator",
                "GPX file export",
                "AI training plans",
                "Marathon training programs",
                "5K and 10K training plans"
              ]
            }
          `}
        </script>
      </Helmet>
      <Container>
        <Header>
          <Title>Running Route Planner & AI Training Plans</Title>
          <SubTitle>Create Perfect Running Routes & Get Personalized Training Programs</SubTitle>
          <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', marginTop: '30px' }}>
            <NavLink href="/running-route-planner">Create Running Route</NavLink>
            <NavLink href="/training-hub">Get Training Plan</NavLink>
          </div>
        </Header>

        <FeaturesGrid>
          <FeatureCard>
            <FeatureIcon><FaMap /></FeatureIcon>
            <FeatureTitle>Running Route Creator</FeatureTitle>
            <FeatureDescription>
              Draw custom routes or generate round-trip runs. Perfect for discovering new running paths in your area.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureIcon><FaMountain /></FeatureIcon>
            <FeatureTitle>AI Running Coach</FeatureTitle>
            <FeatureDescription>
              Get personalized training plans for 5K, 10K, half-marathon, and marathon. Tailored to your next fitness goal.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureIcon><FaCloud /></FeatureIcon>
            <FeatureTitle>Runner's Toolkit</FeatureTitle>
            <FeatureDescription>
              Export routes as GPX files, track your progress, and follow structured training programs for optimal results.
            </FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>

        <ContentSection>
          <ContentContainer>
            <h2>Complete Running Training Solutions</h2>
            <p>Discover personalized running training plans powered by artificial intelligence, designed for runners of all levels - from beginners to elite athletes.</p>

            <h3>Specialized Training Programs</h3>
            <ul>
              <li>5K Training Plans - Perfect for beginners and speed improvement</li>
              <li>10K Training Programs - Build endurance and pace</li>
              <li>Half Marathon Preparation - Structured plans for 13.1 miles</li>
              <li>Marathon Training - Comprehensive 16-26 week programs</li>
            </ul>

            <h3>AI-Powered Running Features</h3>
            <ul>
              <li>Progressive distance building</li>
              <li>Interval training for speed development</li>
              <li>Recovery periods to prevent injury</li>
            </ul>
          </ContentContainer>
        </ContentSection>

        <Footer>
          <FooterText>&copy; {new Date().getFullYear()} AI Running Training. All rights reserved.</FooterText>
        </Footer>
      </Container>
    </>
  );
};

export default HomePage;
