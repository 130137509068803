import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

interface BoxProps {
  mt?: number;
  mb?: number;
  p?: number;
}

const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 0 16px;
`;

const Box = styled.div<BoxProps>`
  margin-top: ${props => props.mt || 0}px;
  margin-bottom: ${props => props.mb || 0}px;
  padding: ${props => props.p || 0}px;
`;

const Typography = styled.h1`
  text-align: center;
  margin-bottom: 16px;
`;

const Paper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background-color: #ccc;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  padding: 16px;
`;

const GridItem = styled.div`
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1976d2;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  gap: 16px;
`;

interface TrainingPlan {
  name: string;
  plan: string;
  planId: string;
}

const ViewTrainingPlans: React.FC = () => {
  const [trainingPlans, setTrainingPlans] = useState<TrainingPlan[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchTrainingPlan(user.username);
    }
  }, [user]);

  const fetchTrainingPlan = async (userId: string) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_TRAINING_PLANS_API_BASE_URL}/allSavedTrainingPlans?userId=${userId}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data && response.data.length > 0) {
        setTrainingPlans(response.data);
      } else {
        console.log('No saved training plans found');
        setTrainingPlans([]);
      }
    } catch (error) {
      console.error('Error fetching training plans:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewPlan = (plan: TrainingPlan) => {
    navigate(`/view-training-plan/${plan.planId}`);
  };

  const handleDeletePlan = async (planName: string, planId: string) => {
    if (window.confirm('Are you sure you want to delete this training plan?')) {
      try {
        await axios.delete(`${process.env.REACT_APP_TRAINING_PLANS_API_BASE_URL}/deleteTrainingPlan?`, {
          data: {
            userId: user?.username,
            planId: planId
          }
        });
        
        setTrainingPlans(prevPlans => prevPlans.filter(plan => plan.name !== planName));
      } catch (error) {
        console.error('Error deleting training plan:', error);
      }
    }
  };

  return (
    <Container>
      {isLoading ? (
        <LoadingContainer>
          <LoadingSpinner />
          <p>Loading your training plans...</p>
        </LoadingContainer>
      ) : trainingPlans.length > 0 ? (
        <Box mt={32} mb={32}>
          <Typography as="h1">Your Training Plans</Typography>
          <Paper>
            <GridContainer>
              {trainingPlans.map((plan, index) => (
                <GridItem key={index}>
                  <h3>{plan.name || 'Unnamed Plan'}</h3>
                  <Box mt={8}>
                    <Button onClick={() => handleViewPlan(plan)}>
                      View Plan
                    </Button>
                    <Button 
                      onClick={() => handleDeletePlan(plan.name, plan.planId)}
                      style={{ backgroundColor: '#dc3545', marginTop: '8px' }}
                    >
                      Delete Plan
                    </Button>
                  </Box>
                </GridItem>
              ))}
            </GridContainer>
          </Paper>
          <Box mt={16}>
            <Link to="/ai-training-plan-generator">
              <Button>Generate New Plan</Button>
            </Link>
          </Box>
        </Box>
      ) : (
        <Box mt={32} mb={32}>
          <Typography>You don't have any training plans yet</Typography>
          <Box mt={16}>
            <Link to="/ai-training-plan-generator">
              <Button>Generate New Plan</Button>
            </Link>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default ViewTrainingPlans;
