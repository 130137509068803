/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9fafb;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h1`
  text-align: center;
  color: #264653;
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
`;

const SubHeader = styled.h2`
  color: #2a9d8f;
  font-size: 1.8rem;
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  color: #555555;
  line-height: 1.6;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
`;

const Highlight = styled.span`
  color: #e76f51;
  font-weight: bold;
`;

const AboutPage: React.FC = () => {
  return (
    <Container>
      <Header>About airunningtraining</Header>
      <SubHeader>Our Mission</SubHeader>
      <Paragraph>
        At <Highlight>airunningtraining</Highlight>, we are passionate about helping runners of all levels achieve their goals. Whether you're training for your first 5K, preparing for a marathon, or simply looking to improve your pace, we provide the tools, resources, and community to support your journey.
      </Paragraph>
      <SubHeader>What We Offer</SubHeader>
      <Paragraph>
        <Highlight>airunningtraining</Highlight> is more than just a training platform—it's a comprehensive resource for all things running. From personalized training plans to pace calculators, and conversion charts, our goal is to be your go-to source for running information and tools.
      </Paragraph>
      <Paragraph>
        Our expertly crafted training plans are designed to meet the needs of beginners and seasoned runners alike. Each plan is tailored to help you build endurance, speed, and confidence, ensuring you’re ready to tackle your next race with ease.
      </Paragraph>
      <SubHeader>Our Story</SubHeader>
      <Paragraph>
        Founded by runners for runners, <Highlight>airunningtraining</Highlight> was born out of a desire to create a space where runners can find everything they need to succeed. We understand the challenges that come with training for races and wanted to offer a solution that makes the process easier, more enjoyable, and ultimately more successful.
      </Paragraph>
      <SubHeader>Join Our Community</SubHeader>
      <Paragraph>
        Running is more than just a sport—it's a lifestyle. We invite you to join our community of runners who inspire, motivate, and support each other every step of the way. Follow us on social media, share your progress, and connect with like-minded individuals who share your passion for running.
      </Paragraph>
      <Paragraph>
        Thank you for choosing <Highlight>airunningtraining</Highlight> as your running partner. We’re excited to be part of your journey and can’t wait to see what you achieve.
      </Paragraph>
    </Container>
  );
};

export default AboutPage;
