import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axios from 'axios';

const CalculatorContainer = styled.div`
  flex: 1;
  background-color: #f0f4f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 16px;
`;


const Button = styled.button`
  padding: 10px 15px;
  background-color: #2a9d8f;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #238b7e;
  }
`;

const ResultsContainer = styled.div`
  margin-top: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 30px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const MainContent = styled.div`
  flex: 2;
  order: 2;

  @media (max-width: 1200px) {
    order: 1;
  }
`;

const LeftSidebar = styled.div`
  width: 20%;
  order: 1;

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 600px;
    order: 3;
  }
`;

const RightSidebar = styled.div`
  width: 20%;
  order: 3;

  @media (max-width: 1200px) {
    width: 100%;
    order: 2;
  }
`;

const RelatedBlogsContainer = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const RelatedBlogsHeader = styled.h2`
  font-size: 1.5rem;
  color: #264653;
  margin-bottom: 20px;
  text-align: center;
`;

const RelatedBlogItem = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.3s ease;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  &:hover {
    background-color: #f0f4f8;
  }
`;

const RelatedBlogThumbnail = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 15px;
`;

const RelatedBlogInfo = styled.div`
  flex: 1;
`;

const RelatedBlogTitle = styled.h3`
  font-size: 1rem;
  color: #2a9d8f;
  margin-bottom: 5px;
  transition: text-decoration 0.3s ease;

  &:hover {
    text-decoration: underline;
  }
`;

const RelatedBlogTopic = styled(Link)`
  display: inline-block;
  background-color: #e0f7fa;
  color: #006064;
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 0.7rem;
  margin-bottom: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #b2ebf2;
  }
`;

const UnitToggleGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
`;

const UnitToggle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ToggleButton = styled.button<{ active: boolean }>`
  padding: 5px 10px;
  background-color: ${props => props.active ? '#2a9d8f' : '#e9ecef'};
  color: ${props => props.active ? 'white' : '#495057'};
  border: 1px solid #ced4da;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.active ? '#238b7e' : '#ced4da'};
  }
`;

const PageTitle = styled.h1`
  font-size: 2rem;
  color: #264653;
  margin-bottom: 20px;
  text-align: center;
`;

interface Blog {
  slug: string;
  title: string;
  topic: string;
  image: string;
}

const CalorieBurnCalculator: React.FC = () => {
  const [weight, setWeight] = useState<number>(0);
  const [distance, setDistance] = useState<number>(0);
  const [pace, setPace] = useState<number>(0);
  const [calories, setCalories] = useState<number | null>(null);
  const [isKg, setIsKg] = useState<boolean>(false);
  const [isKm, setIsKm] = useState<boolean>(false);
  const [relatedBlogs, setRelatedBlogs] = useState<Blog[]>([]);

  const calculateCalories = (e: React.FormEvent) => {
    e.preventDefault();
    
    let weightInKg = isKg ? weight : weight * 0.453592; // Convert lbs to kg if needed
    let distanceInKm = isKm ? distance : distance * 1.60934; // Convert miles to km if needed
    
    // Adjust for pace (very rough estimate)
    const paceAdjustment = isKm
      ? (pace < 5 ? 1.2 : pace < 6.2 ? 1 : 0.8) // km/min pace
      : (pace < 8 ? 1.2 : pace < 10 ? 1 : 0.8); // mile/min pace
    
    const caloriesBurned = weightInKg * distanceInKm * 1.036 * paceAdjustment;
    setCalories(Math.round(caloriesBurned));
  };

  const getRelatedBlogs = async () => {
    try {
      const response = await axios.get('https://d2em90gkaptml7.cloudfront.net/prod/getAllBlogs');
      const parsedData = JSON.parse(response.data.body);
      const blogs = parsedData.blog_posts
        .filter((post: { tags: string[] }) => post.tags && post.tags.includes('Running Weight Loss'))
        .slice(0, 3)
        .map((post: { slug: string; title: string; topic: string; image: string }) => ({
          slug: post.slug,
          title: post.title,
          topic: post.topic,
          image: post.image,
        }));
      setRelatedBlogs(blogs);
    } catch (error) {
      console.error('Error fetching related blogs:', error);
      setRelatedBlogs([]);
    }
  };

  useEffect(() => {
    getRelatedBlogs();
  }, []);

  return (
    <>
      <Helmet>
        <title>Running Calorie Burn Calculator | Estimate Calories Burned While Running</title>
        <meta 
          name="description" 
          content="Use our accurate Running Calorie Burn Calculator to estimate calories burned during your run. Input weight, distance, and pace for personalized results. Free tool for runners."
        />
        <meta name="keywords" content="running calculator, calorie burn, calories burned running, running calorie estimator, fitness tool" />
        <link rel="canonical" href="https://yourwebsite.com/calorie-burn-calculator" />
        <meta property="og:title" content="Running Calorie Burn Calculator | Estimate Calories Burned While Running" />
        <meta property="og:description" content="Estimate calories burned during your run with our accurate Running Calorie Burn Calculator. Free tool for runners to optimize their training." />
        <meta property="og:url" content="https://yourwebsite.com/calorie-burn-calculator" />
        <meta property="og:type" content="website" />
      </Helmet>
      <ContentWrapper>
        <LeftSidebar>
          {/* Add content for left sidebar if needed */}
        </LeftSidebar>
        <MainContent>
          <CalculatorContainer>
            <h1>Running Calorie Burn Calculator</h1>
            <p>Estimate the number of calories you burn while running. Enter your weight, distance, and pace for an accurate calculation.</p>
            <Form onSubmit={calculateCalories}>
              <UnitToggleGroup>
                <UnitToggle>
                  <ToggleButton 
                    type="button"
                    active={!isKg} 
                    onClick={() => setIsKg(false)}
                  >
                    Pounds (lbs)
                  </ToggleButton>
                  <ToggleButton 
                    type="button"
                    active={isKg} 
                    onClick={() => setIsKg(true)}
                  >
                    Kilograms (kg)
                  </ToggleButton>
                </UnitToggle>
                <UnitToggle>
                  <ToggleButton 
                    type="button"
                    active={!isKm} 
                    onClick={() => setIsKm(false)}
                  >
                    Miles
                  </ToggleButton>
                  <ToggleButton 
                    type="button"
                    active={isKm} 
                    onClick={() => setIsKm(true)}
                  >
                    Kilometers
                  </ToggleButton>
                </UnitToggle>
              </UnitToggleGroup>
              <Input 
                type="number" 
                placeholder={`Weight (${isKg ? 'kg' : 'lbs'})`} 
                value={weight || ''} 
                onChange={(e) => setWeight(Number(e.target.value))}
                required
              />
              <Input 
                type="number" 
                placeholder={`Distance (${isKm ? 'km' : 'miles'})`} 
                value={distance || ''} 
                onChange={(e) => setDistance(Number(e.target.value))}
                required
              />
              <Input 
                type="number" 
                placeholder={`Pace (minutes per ${isKm ? 'km' : 'mile'})`} 
                value={pace || ''} 
                onChange={(e) => setPace(Number(e.target.value))}
                required
              />
              <Button type="submit">Calculate</Button>
            </Form>
            {calories !== null && (
              <ResultsContainer>
                Estimated calories burned: {calories} calories
              </ResultsContainer>
            )}
          </CalculatorContainer>
          <section>
            <h2>How to Use the Running Calorie Burn Calculator</h2>
            <p>Follow these steps to estimate the calories burned during your run:</p>
            <ol>
              <li>Enter your weight in pounds (lbs) or kilograms (kg).</li>
              <li>Input the distance you ran in miles or kilometers.</li>
              <li>Specify your average pace in minutes per mile or kilometer.</li>
              <li>Click the "Calculate" button to see your results.</li>
            </ol>
            <p>Our calculator takes into account your weight, distance, and pace to provide an accurate estimate of calories burned.</p>
          </section>
          <section>
            <h2>Why Use a Running Calorie Calculator?</h2>
            <p>Understanding the number of calories burned during your runs can help you:</p>
            <ul>
              <li>Set realistic weight loss or maintenance goals</li>
              <li>Plan your nutrition and refueling strategies</li>
              <li>Track your progress and adjust your training intensity</li>
              <li>Stay motivated by seeing the impact of your efforts</li>
            </ul>
          </section>
        </MainContent>
        <RightSidebar>
          <RelatedBlogsContainer>
            <RelatedBlogsHeader>Related Blogs</RelatedBlogsHeader>
            {relatedBlogs.map(blog => (
              <RelatedBlogItem 
                key={blog.slug} 
                to={`/blog/${blog.topic.toLowerCase().replace(/\s+/g, '-')}/${blog.slug}`}
              >
                <RelatedBlogThumbnail 
                  src={`https://public-blog-images-mlp.s3.amazonaws.com/${blog.slug}.png`}
                  alt={blog.title} 
                />
                <RelatedBlogInfo>
                  <RelatedBlogTitle className="related-blog-title">{blog.title}</RelatedBlogTitle>
                  <RelatedBlogTopic 
                    to={`/blogs/${blog.topic.toLowerCase().replace(/\s+/g, '-')}`}
                  >
                    {blog.topic}
                  </RelatedBlogTopic>
                </RelatedBlogInfo>
              </RelatedBlogItem>
            ))}
          </RelatedBlogsContainer>
        </RightSidebar>
      </ContentWrapper>
    </>
  );
};

export default CalorieBurnCalculator;
