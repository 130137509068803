import React from 'react';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useAuth } from '../../contexts/AuthContext';
import ReactMarkdown from 'react-markdown';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Hero = styled.div`
  text-align: center;
  padding: 60px 20px;
  background: linear-gradient(135deg, #2a9d8f 0%, #21867a 100%);
  color: white;
  border-radius: 10px;
  margin-bottom: 40px;
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;
  color: #ffffff;
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 30px;
`;

const CTAButton = styled(Link)`
  display: inline-block;
  padding: 12px 30px;
  background-color: #ffffff;
  color: #2a9d8f;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f2f5;
    transform: none;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin: 40px 0;
`;

const FeatureCard = styled.div`
  padding: 30px 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
  }
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #264653;
`;

const FeatureDescription = styled.p`
  color: #555555;
  line-height: 1.6;
`;

const BlogSection = styled.section`
  margin-top: 60px;
  padding: 40px 0;
  border-top: 1px solid #eaeaea;
`;

const BlogTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  color: #264653;
  margin-bottom: 40px;
`;

const BlogContent = styled.article`
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.8;
  color: #333;
  
  h1, h2, h3, h4, h5, h6 {
    color: #264653;
    margin: 1.5em 0 0.8em;
  }
  
  h1 { font-size: 2.2rem; }
  h2 { font-size: 1.8rem; }
  h3 { font-size: 1.5rem; }
  
  p {
    margin-bottom: 1.2em;
  }
  
  ul, ol {
    margin-bottom: 1.2em;
    padding-left: 2em;
  }
  
  li {
    margin-bottom: 0.5em;
  }
`;

const markdownContent = `# AI Running Training Plan: Data-Driven Running Success

Artificial intelligence (AI) is revolutionizing many areas of our lives, including the way we train for athletic events. For runners at all levels, AI-powered training plans offer structured, scientific approaches to enhance performance, reduce the risk of injury, and provide clear steps toward fitness goals. In this post, we’ll take a deep dive into the world of **AI running training plans**.

Whether you're preparing for a 5K or a full marathon, an AI-generated training plan can provide a high-quality, data-driven framework to guide your journey. Let's explore how AI-designed training plans work, their advantages, potential downsides, and tips for maximizing your results with these high-tech tools.

---

## What Is an AI Running Training Plan?

An AI running training plan uses algorithms and pre-programmed data to generate a structured training regimen. By inputting information such as your current fitness level, race goals, training time available, and past performance metrics, AI systems analyze the data to craft a running schedule optimized for your specific needs.

### Key Aspects of an AI Training Plan

1. **Customizable Inputs**: AI Training Plans are designed based on initial input data. When setting up the plan, you'll typically be asked about your:
   - Target race distance (5K, 10K, half marathon, marathon, etc.)
   - Current fitness level or weekly mileage
   - Preferred days for training and rest
   - Time available for each session
2. **Consistent Schedule**: The AI will generate a schedule from start to finish. Every session is planned according to initial data, without daily or weekly adjustments.
3. **Evidence-Based Approach**: AI-based training plans often incorporate scientific research on endurance, recovery, and periodization to offer a schedule that prioritizes effective training loads and rest.
4. **Goal-Focused**: Each AI-generated plan is tailored to help you reach a specific goal, such as finishing a 10K in under 50 minutes or completing a marathon for the first time.

---

## Benefits of an AI Running Training Plan

### 1. **Scientifically Structured Plans**

AI-generated running plans benefit from data on human performance and exercise physiology. They often implement proven training techniques, such as periodization, which involves gradually increasing training intensity or distance, followed by rest periods to maximize performance. By following an AI-designed plan, runners gain access to sophisticated structures used by coaches and athletes, without needing a human coach.

### 2. **Convenience and Simplicity**

For runners who want a straightforward, hassle-free approach, an AI plan offers a fixed structure that doesn't require constant monitoring or adjustment. Once you set your parameters, the plan remains consistent, allowing you to follow a predictable routine. This can be particularly beneficial for busy individuals who want to avoid the complexity of daily plan adjustments.

### 3. **Cost-Effectiveness**

Many AI-based plans are more affordable than hiring a personal coach, while still providing a high level of structure and quality. These plans can be a great solution for runners who want a professional training plan without the premium cost of individualized coaching.

### 4. **Goal Clarity and Focus**

An AI training plan gives runners a clear path to their goal from day one. For example, if you want to run a half marathon in under two hours, your AI plan will show the progression of mileage and pace required to achieve that, making the journey from start to finish highly visible. With each session already defined, you can see how each workout contributes to your ultimate goal.

### 5. **Reduction of Decision Fatigue**

An AI training plan provides a specific workout each day, which eliminates the guesswork and decision-making that can be overwhelming for runners who are self-coaching. With a fixed plan in place, you know exactly what your next workout is, allowing you to focus your energy on executing it rather than planning it.

---

## The Science Behind AI Running Training Plans

### 1. **Periodization and Progression**

A well-designed AI training plan uses periodization, a training principle that organizes workouts into phases with increasing intensity or mileage over time. These phases typically include:
   - **Base Phase**: Building foundational mileage and aerobic endurance.
   - **Build Phase**: Increasing intensity with tempo runs, intervals, or hills.
   - **Peak Phase**: Preparing for race conditions with longer runs at or near race pace.
   - **Taper Phase**: Reducing mileage for optimal rest before race day.

AI systems structure these phases based on your initial fitness level, goal race distance, and timeline, ensuring you peak at the right time without overtraining.

### 2. **Pace and Heart Rate Zones**

AI training plans often recommend specific paces or heart rate zones for different workouts. By defining easy runs, long runs, tempo workouts, and speed intervals, the plan ensures you’re training at intensities aligned with your goals. Some plans use your recent race times or training paces to calculate target paces for each type of run, making sure you're training effectively without risking burnout.

### 3. **Balancing Workload and Recovery**

AI plans take rest days and active recovery into account, crucial for preventing injuries and allowing for performance gains. Rest days, lower-intensity workouts, and cutback weeks (where mileage is reduced) are all planned in advance, creating a balanced workload that accommodates the body’s need for recovery alongside progressive training.

---

## Creating Your Own AI-Based Training Plan

While AI-generated plans offer a great starting point, there are ways to tailor them even further to suit your individual preferences and needs. Here’s how you can maximize the effectiveness of your plan:

### 1. **Be Honest About Your Current Fitness Level**

When setting up your AI plan, be realistic about your fitness level. If you're a beginner, choose a plan that starts at a lower mileage and builds gradually. If you're a seasoned runner, select a plan that incorporates the necessary intensity to help you improve. Setting an accurate baseline allows the AI system to create a plan that’s challenging yet achievable.

### 2. **Align the Plan with Your Lifestyle**

AI training plans are only effective if you can follow them consistently. Be mindful of your weekly schedule, work commitments, and personal life when choosing your training days. Many AI systems allow you to select specific days for longer runs or speed work, so make sure the schedule fits into your life.

### 3. **Select a Realistic Goal**

Setting a goal that aligns with your experience and current fitness level is key. If you’re a beginner aiming for a marathon, choose a longer training timeline to build adequate endurance. Intermediate runners aiming for a personal best might consider a plan with a higher frequency of speed workouts or tempo runs.

---

## Types of Workouts in AI Running Training Plans

Most AI-generated training plans incorporate a variety of workouts to build different aspects of running fitness. Here’s a breakdown of common types of runs you’ll find in these plans:

1. **Easy Runs**: Low-intensity runs that promote endurance and active recovery.
2. **Tempo Runs**: Moderate-to-hard runs that improve lactate threshold, helping you sustain faster paces over longer distances.
3. **Long Runs**: The weekly long run builds endurance and prepares you for race distance.
4. **Intervals**: Short bursts of high-intensity running with rest periods, enhancing speed and aerobic capacity.
5. **Hill Workouts**: Training on hills to build strength and improve running form.

Each of these workout types serves a unique purpose, contributing to overall fitness and helping you progress safely toward your goal.

---

## Tips for Getting the Most Out of Your AI Running Plan

1. **Listen to Your Body**: If a workout feels too intense, consider adjusting the intensity or taking an extra rest day. Sticking rigidly to the plan isn't always the best approach, especially if you’re experiencing discomfort or fatigue.
2. **Use Cross-Training Wisely**: Supplementing your plan with activities like cycling, swimming, or strength training can improve overall fitness and help prevent injury.
3. **Track Your Progress**: Use a fitness tracker, app, or simple journal to monitor your progress. Tracking your times and effort levels can help you gauge improvements and adjust pace goals for future races.
4. **Take Advantage of Recovery Days**: Respect rest and recovery days—they're essential for muscle repair and help you avoid burnout.

---

## Sample AI Running Training Plan for a Beginner 5K

Below is an example of a four-week AI running plan for a beginner aiming to complete a 5K. This plan assumes no major experience and focuses on building up to 3.1 miles with a gradual increase in distance and intensity.

### Week 1
- **Monday**: Rest
- **Tuesday**: 1-mile easy run
- **Wednesday**: Rest
- **Thursday**: 1.5-mile easy run
- **Friday**: Rest
- **Saturday**: 1-mile walk/jog
- **Sunday**: 2-mile long run

### Week 2
- **Monday**: Rest
- **Tuesday**: 1.5-mile easy run
- **Wednesday**: Rest
- **Thursday**: 2-mile easy run
- **Friday**: Rest
- **Saturday**: 1-mile walk/jog
- **Sunday**: 2.5-mile long run

### Week 3
- **Monday**: Rest
- **Tuesday**: 2-mile easy run
- **Wednesday**: Rest
- **Thursday**: 2.5-mile easy run
- **Friday**: Rest
- **Saturday**: 1-mile walk/jog
- **Sunday**: 3-mile long run

### Week 4
- **Monday**: Rest
- **Tuesday**: 2.5-mile easy run
- **Wednesday**: Rest
- **Thursday**: 2-mile tempo run
- **Friday**: Rest
- **Saturday**: Rest
- **Sunday**: 5K race or practice run

---



An **AI running training plan** offers a scientifically structured, convenient, and goal-focused approach to running. AI training plans provide clear guidance on workout types, frequency, and progression, making it easy for runners to follow a consistent path toward their goals. While these plans are fixed and do not adjust for daily feedback, they can be highly effective for runners seeking a straightforward, data-driven regimen. By understanding the plan’s strengths, applying consistent effort, and listening to your body, you can harness the power of AI to improve your performance and achieve your running goals.
`

const TrainingHub: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();

  return (
    <>
      <Helmet>
        <title>AI Running Training Plan Generator | Personalized Training Plans</title>
        <meta name="description" content="Create your personalized AI running training plan. Get a custom training schedule tailored to your race goals, fitness level, and availability. Free AI-powered running plans." />
        <meta name="keywords" content="ai running training plan, running plan generator, personalized training plan, ai running coach" />
        <meta property="og:title" content="AI Running Training Plan Generator" />
        <meta property="og:description" content="Create your personalized AI running training plan. Get a custom training schedule tailored to your race goals." />
      </Helmet>

      <Container as="main">
        <Hero as="section">
          <HeroTitle as="h1">AI Running Training Plan Generator</HeroTitle>
          <HeroSubtitle>
            Get your personalized running training plan powered by artificial intelligence
          </HeroSubtitle>
          <CTAButton 
            to={user ? "/ai-training-plan-generator" : "/login"}
            state={{ from: "/training-hub" }}
            style={{ marginBottom: '15px' }}
          >
            {user ? "Generate Your Free Running Plan" : "Sign in to Generate Your Free Plan"}
          </CTAButton>

          <div style={{ display: 'block' }}>
            <CTAButton 
              to={user ? "/view-training-plans" : "/login"}
              state={{ from: "/training-hub" }}
            >
              View Your Training Plans
            </CTAButton>
          </div>
        </Hero>

        <FeaturesGrid as="section" aria-label="Features">
          <FeatureCard>
            <FeatureTitle>Smart AI Running Coach</FeatureTitle>
            <FeatureDescription>
              Our intelligent AI running coach analyzes your race goals, current fitness level, and schedule to create
              the perfect training plan customized for your needs.
            </FeatureDescription>
          </FeatureCard>

          <FeatureCard>
            <FeatureTitle>Adaptive Training Plans</FeatureTitle>
            <FeatureDescription>
              Track your running progress and log completed workouts.
            </FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>
        
        <BlogSection>
          <BlogTitle>AI Running Training Plans</BlogTitle>
          <BlogContent>
            <ReactMarkdown>{markdownContent}</ReactMarkdown>
          </BlogContent>
        </BlogSection>
      </Container>
    </>
  );
};

export default TrainingHub;
