/**
 * Calculates the estimated pace for a runner based on elevation data and distance.
 * @param {Array} elevationData - Array of objects containing elevation and distance data.
 * @param {number} totalDistance - Total distance of the route in meters.
 * @returns {string} Estimated pace in min/km format.
 */
export const calculatePace = (elevationData: { elevation: number }[], totalDistance: number): string => {
  const basePace = 5.5; // Base pace in minutes per kilometer for flat terrain
  const elevationFactor = 0.2; // Minutes added per 100m of elevation gain
  const distanceFactor = 0.1; // Minutes added per km for longer distances

  // Calculate total elevation gain
  let totalElevationGain = 0;
  for (let i = 1; i < elevationData.length; i++) {
    const elevationDiff = elevationData[i].elevation - elevationData[i - 1].elevation;
    if (elevationDiff > 0) {
      totalElevationGain += elevationDiff;
    }
  }

  // Convert totalDistance to kilometers
  const distanceKm = totalDistance / 1000;

  // Calculate pace adjustments
  const elevationAdjustment = (totalElevationGain / 100) * elevationFactor;
  const distanceAdjustment = Math.max(0, distanceKm - 5) * distanceFactor; // Apply factor only after 5km

  // Calculate final pace
  const adjustedPace = basePace + elevationAdjustment + distanceAdjustment;

  // Convert pace to minutes and seconds
  const paceMinutes = Math.floor(adjustedPace);
  const paceSeconds = Math.round((adjustedPace - paceMinutes) * 60);

  return `${paceMinutes}:${paceSeconds.toString().padStart(2, '0')}`;
};