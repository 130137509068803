import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { Typography, Card, CardHeader, CardContent, CardActions, Button } from './StyledComponents';
import axios from 'axios';
import { usePreferences } from '../../contexts/Preferences';
import { useAuth } from '../../contexts/AuthContext';

const PageContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
`;

const DetailedWorkoutCard = styled(Card)`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const CardHeaderStyled = styled(CardHeader)`
  text-align: left;
`;

const WorkoutSection = styled.div`
  margin-bottom: 24px;
  text-align: left;
`;

const SectionTitle = styled(Typography)`
  color: #3f51b5;
  margin-bottom: 8px;
  text-align: left;
`;

const SectionContent = styled.p`
  margin: 0;
  line-height: 1.6;
`;

const ActionButton = styled(Button)`
  margin: 0 8px;
`;

const DetailedWorkoutView = () => {
  const { user } = useAuth();
  const { weekIndex, day, planId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [trainingDay, setTrainingDay] = useState(location.state?.trainingDay || null);
  const [trainingPlan, setTrainingPlan] = useState(location.state?.trainingPlan || null);
  const [updatedTrainingPlan, setUpdatedTrainingPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isKm = location.state?.isKm;

  const handleClose = () => {
    navigate(`/view-training-plan/${planId}`);
  };

  const handleCompleteWorkout = async () => {
    setIsLoading(true);
    try {
      console.log('trainingPlan', trainingPlan)
      if (trainingPlan.plan && weekIndex && day && user) {
        const updatedPlan = Object.values(trainingPlan.plan).map(week => ({
          week: week.week,
          days: week.days
        }));

        console.log('trainingPlan name', trainingPlan.name)
        
        const weekData = updatedPlan[parseInt(weekIndex)];
        const dayIndex = weekData.days.findIndex(d => d.day === day);
        
        if (dayIndex !== -1) {
          updatedPlan[parseInt(weekIndex)].days[dayIndex].completed = true;
          
          const response = await axios.put(`${process.env.REACT_APP_TRAINING_PLANS_API_BASE_URL}/updateTrainingPlan?userId=${user.username}&planId=${planId}`, {
            plan: updatedPlan,
            name: trainingPlan.name,
            targetTime: trainingPlan.targetTime,
            goal: trainingPlan.goal,
            duration: trainingPlan.duration
          }, {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true
          });

          if (response.data.message === 'Plan saved successfully') {
            setTrainingPlan(updatedPlan);
            setTrainingDay({ ...trainingDay, completed: true });
            localStorage.setItem('trainingPlan', JSON.stringify(updatedPlan));
          } else {
            console.error('Failed to save training plan:', response.data);
            // Add error handling UI here
          }
        }
      }
    } catch (error) {
      console.error('Error saving training plan:', error);
      // Add error handling UI here
    } finally {
      setIsLoading(false);
    }
  };

  if (!trainingDay) {
    return <div>No workout data available.</div>;
  }

  return (
    <PageContainer>
      <ScrollableContent>
        <DetailedWorkoutCard>
          <CardHeaderStyled>
            <Typography as="h2" style={{ color: '#3f51b5' }}>{trainingDay.day} Detailed Workout</Typography>
          </CardHeaderStyled>
          <CardContent>
            <WorkoutSection>
              <SectionTitle as="h3">Workout:</SectionTitle>
              <SectionContent>{trainingDay.workout}</SectionContent>
            </WorkoutSection>
            {trainingDay.distance && (
              <WorkoutSection>
                <SectionTitle as="h3">Distance:</SectionTitle>
                <SectionContent>{trainingDay.distance} {isKm ? ' kilometers' : ' miles'}</SectionContent>
              </WorkoutSection>
            )}
            {trainingDay.pace && (
              <WorkoutSection>
                <SectionTitle as="h3">Pace:</SectionTitle>
                <SectionContent>{trainingDay.pace} {isKm ? ' mins/km' : ' mins/mile'}</SectionContent>
              </WorkoutSection>
            )}
            {trainingDay.estimatedTime && (
              <WorkoutSection>
                <SectionTitle as="h3">Estimated Time:</SectionTitle>
                <SectionContent>{trainingDay.estimatedTime}</SectionContent>
              </WorkoutSection>
            )}
            {trainingDay.notes && !trainingDay.workout.toLowerCase().includes('tempo') && (
              <WorkoutSection>
                <SectionTitle as="h3">Notes:</SectionTitle>
                <SectionContent>{trainingDay.notes}</SectionContent>
              </WorkoutSection>
            )}
          </CardContent>
        </DetailedWorkoutCard>
      </ScrollableContent>
      <CardActions>
        <ActionButton 
          onClick={handleCompleteWorkout} 
          disabled={trainingDay.completed || isLoading}
        >
          {trainingDay.completed 
            ? 'Workout Completed' 
            : isLoading 
              ? 'Completing...' 
              : 'Complete Workout'}
        </ActionButton>
        <ActionButton onClick={handleClose}>Close</ActionButton>
      </CardActions>
    </PageContainer>
  );
};

export default DetailedWorkoutView;
