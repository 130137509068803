import styled from '@emotion/styled';

export const BlogPost = styled.div`
  margin-top: 30px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const BlogTitle = styled.h2`
  color: #264653;
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
`;

export const BlogContent = styled.p`
  font-size: 1rem;
  color: #555;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
`;