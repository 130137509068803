import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Header = styled.h1`
  color: #333;
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  color: #444;
`;

const Paragraph = styled.p`
  line-height: 1.6;
`;

const List = styled.ul`
  margin-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const TermsAndConditions: React.FC = () => {
  return (
    <Container>
      <Header>Terms and Conditions</Header>
      
      <Section>
        <Paragraph>
          Welcome to AiRunningTraining. These terms and conditions outline the rules and regulations for the use of our website.
        </Paragraph>
        <Paragraph>
          By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use AiRunningTraining's website if you do not accept all of the terms and conditions stated on this page.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>1. Definitions</SectionTitle>
        <List>
          <ListItem>"Client", "You" and "Your" refers to you, the person accessing this website and accepting the Company's terms and conditions.</ListItem>
          <ListItem>"The Company", "Ourselves", "We", "Our" and "Us", refers to AiRunningTraining.</ListItem>
          <ListItem>"Party", "Parties", or "Us", refers to both the Client and ourselves, or either the Client or ourselves.</ListItem>
        </List>
        <Paragraph>
          All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services/products, in accordance with and subject to, prevailing law of the United States.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>2. License to Use Website</SectionTitle>
        <Paragraph>
          Unless otherwise stated, AiRunningTraining and/or its licensors own the intellectual property rights for all material on AiRunningTraining. All intellectual property rights are reserved. You may view and/or print pages from https://AiRunningTraining.com for your own personal use subject to restrictions set in these terms and conditions.
        </Paragraph>
        <Paragraph>You must not:</Paragraph>
        <List>
          <ListItem>Republish material from https://AiRunningTraining.com</ListItem>
          <ListItem>Sell, rent or sub-license material from https://AiRunningTraining.com</ListItem>
          <ListItem>Reproduce, duplicate or copy material from https://AiRunningTraining.com</ListItem>
          <ListItem>Redistribute content from AiRunningTraining (unless content is specifically made for redistribution)</ListItem>
        </List>
      </Section>

      <Section>
        <SectionTitle>3. User Content</SectionTitle>
        <Paragraph>
          In these terms and conditions, "User Content" shall mean any audio, video, text, images or other material you choose to display on this website. By displaying your User Content, you grant AiRunningTraining a non-exclusive, worldwide, irrevocable, royalty-free, sublicensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.
        </Paragraph>
        <Paragraph>
          Your User Content must be your own and must not be infringing on any third party's rights. AiRunningTraining reserves the right to remove any of your content from this website at any time, and for any reason, without notice.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>4. No Warranties</SectionTitle>
        <Paragraph>
          This website is provided "as is," with all faults, and AiRunningTraining makes no express or implied representations or warranties, of any kind related to this website or the materials contained on this website. Additionally, nothing contained on this website shall be construed as providing consult or advice to you.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>5. Limitation of Liability</SectionTitle>
        <Paragraph>
          In no event shall AiRunningTraining, nor any of its officers, directors and employees, be liable to you for anything arising out of or in any way connected with your use of this website, whether such liability is under contract, tort or otherwise, and AiRunningTraining, including its officers, directors and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this website.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>6. Indemnification</SectionTitle>
        <Paragraph>
          You hereby indemnify to the fullest extent AiRunningTraining from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney's fees) arising out of or in any way related to your breach of any of the provisions of these terms.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>7. Severability</SectionTitle>
        <Paragraph>
          If any provision of these terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>8. Variation of Terms</SectionTitle>
        <Paragraph>
          AiRunningTraining is permitted to revise these terms at any time as it sees fit, and by using this website you are expected to review such terms on a regular basis to ensure you understand all terms and conditions governing use of this website.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>9. Assignment</SectionTitle>
        <Paragraph>
          AiRunningTraining shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these terms without any notification or consent required. However, you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these terms.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>10. Entire Agreement</SectionTitle>
        <Paragraph>
          These terms, including any legal notices and disclaimers contained on this website, constitute the entire agreement between AiRunningTraining and you in relation to your use of this website, and supersede all prior agreements and understandings with respect to the same.
        </Paragraph>
      </Section>

      <Section>
        <SectionTitle>11. Governing Law & Jurisdiction</SectionTitle>
        <Paragraph>
          These terms will be governed by and construed in accordance with the laws of the United States, and you submit to the non-exclusive jurisdiction of the state and federal courts located in the United States for the resolution of any disputes.
        </Paragraph>
      </Section>
    </Container>
  );
};

export default TermsAndConditions;
