import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import BlogPost from '../blogPosts/RunningPaces';
import styled from '@emotion/styled';

type Unit = 'km' | 'mile';

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  background-color: #f0f4f8; /* Light background color */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #264653; /* Darker shade for titles */
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
`;

const Label = styled.label`
  display: block;
  margin: 15px 0;
  font-size: 1.2rem;
  color: #333;
  font-family: 'Roboto', sans-serif;
`;

const Select = styled.select`
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 10px;
  font-family: 'Roboto', sans-serif;
`;

const Input = styled.input`
  padding: 8px;
  margin: 0 5px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 80px;
  font-family: 'Roboto', sans-serif;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #2a9d8f; /* Vibrant green-blue */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s ease-in-out;
  font-family: 'Roboto', sans-serif;

  &:hover {
    background-color: #e76f51; /* Bright orange for hover */
  }
`;

const Result = styled.h2`
  margin-top: 20px;
  font-size: 1.5rem;
  color: #264653;
  font-family: 'Roboto', sans-serif;
`;

const PaceCalculator: React.FC = () => {
  const [distance, setDistance] = useState<number | null>(null);
  const [timeMinutes, setTimeMinutes] = useState<number | null>(null);
  const [timeSeconds, setTimeSeconds] = useState<number | null>(null);
  const [paceMinutes, setPaceMinutes] = useState<number | null>(null);
  const [paceSeconds, setPaceSeconds] = useState<number | null>(null);
  const [unit, setUnit] = useState<Unit>('km');

  const handleCalculatePace = () => {
    if (distance && (timeMinutes || timeSeconds)) {
      const totalTimeInMinutes = (timeMinutes || 0) + (timeSeconds || 0) / 60;
      const pacePerUnit = totalTimeInMinutes / distance;
      const minutes = Math.floor(pacePerUnit);
      const seconds = Math.round((pacePerUnit - minutes) * 60);
      setPaceMinutes(minutes);
      setPaceSeconds(seconds);
    }
  };

  const handleCalculateTime = () => {
    if (distance && (paceMinutes || paceSeconds)) {
      const paceInMinutes = (paceMinutes || 0) + (paceSeconds || 0) / 60;
      const totalTimeInMinutes = paceInMinutes * distance;
      const minutes = Math.floor(totalTimeInMinutes);
      const seconds = Math.round((totalTimeInMinutes - minutes) * 60);
      setTimeMinutes(minutes);
      setTimeSeconds(seconds);
    }
  };

  const handleCalculateDistance = () => {
    if ((timeMinutes || timeSeconds) && (paceMinutes || paceSeconds)) {
      const totalTimeInMinutes = (timeMinutes || 0) + (timeSeconds || 0) / 60;
      const paceInMinutes = (paceMinutes || 0) + (paceSeconds || 0) / 60;
      const calculatedDistance = totalTimeInMinutes / paceInMinutes;
      setDistance(calculatedDistance);
    }
  };

  const resetFields = () => {
    setDistance(null);
    setTimeMinutes(null);
    setTimeSeconds(null);
    setPaceMinutes(null);
    setPaceSeconds(null);
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "Running Pace Calculator",
    "description": "Calculate your running pace, time, or distance with this easy-to-use tool.",
    "url": "https://airunningtraining.com/paceCalculator",
    "applicationCategory": "Sports",
    "operatingSystem": "All"
  };

  return (
    <Container>
      <Helmet>
        <title>Running Pace Calculator | Calculate Your Running Pace, Time, and Distance</title>
        <meta name="description" content="Use our free running pace calculator to easily determine your pace, finish time, or distance for your runs. Perfect for runners of all levels." />
        <meta name="keywords" content="running pace calculator, running calculator, pace calculator, running time calculator, distance calculator" />
        <link rel="canonical" href="https://airunningtraining.com/paceCalculator" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <header>
        <Title>Running Pace Calculator</Title>
      </header>

      <main>
        <section aria-labelledby="unit-selection">
          <h2 id="unit-selection" className="visually-hidden">Unit Selection</h2>
          <Label>
            Unit:
            <Select value={unit} onChange={(e) => setUnit(e.target.value as Unit)} aria-label="Select distance unit">
              <option value="km">Kilometers</option>
              <option value="mile">Miles</option>
            </Select>
          </Label>
        </section>

        <section aria-labelledby="calculator-inputs">
          <h2 id="calculator-inputs" className="visually-hidden">Calculator Inputs</h2>
          <div>
            <Label htmlFor="distance">
              Distance ({unit}):
              <Input
                type="number"
                id="distance"
                value={distance || ''}
                onChange={(e) => setDistance(parseFloat(e.target.value))}
                aria-label={`Distance in ${unit}`}
              />
            </Label>
          </div>
          <div>
            <Label>
              Time:
              <Input
                type="number"
                placeholder="Minutes"
                value={timeMinutes || ''}
                onChange={(e) => setTimeMinutes(parseFloat(e.target.value))}
                aria-label="Time in minutes"
              />
              <Input
                type="number"
                placeholder="Seconds"
                value={timeSeconds || ''}
                onChange={(e) => setTimeSeconds(parseFloat(e.target.value))}
                aria-label="Time in seconds"
              />
            </Label>
          </div>
          <div>
            <Label>
              Pace:
              <Input
                type="number"
                placeholder="Minutes"
                value={paceMinutes || ''}
                onChange={(e) => setPaceMinutes(parseFloat(e.target.value))}
                aria-label="Pace in minutes"
              />
              <Input
                type="number"
                placeholder="Seconds"
                value={paceSeconds || ''}
                onChange={(e) => setPaceSeconds(parseFloat(e.target.value))}
                aria-label="Pace in seconds"
              />
            </Label>
          </div>
        </section>

        <ButtonContainer>
          <Button onClick={handleCalculatePace} aria-label="Calculate Pace">Calculate Pace</Button>
          <Button onClick={handleCalculateTime} aria-label="Calculate Time">Calculate Time</Button>
          <Button onClick={handleCalculateDistance} aria-label="Calculate Distance">Calculate Distance</Button>
          <Button onClick={resetFields} aria-label="Reset all fields">Reset</Button>
        </ButtonContainer>

        <section aria-live="polite" aria-atomic="true">
          {paceMinutes !== null && (
            <Result>
              Pace: {paceMinutes}:{paceSeconds?.toString().padStart(2, '0')} per {unit}
            </Result>
          )}
          {timeMinutes !== null && (
            <Result>
              Time: {timeMinutes} minutes {timeSeconds?.toString().padStart(2, '0')} seconds
            </Result>
          )}
          {distance !== null && (
            <Result>
              Distance: {distance.toFixed(2)} {unit}
            </Result>
          )}
        </section>
      </main>

      <BlogPost />
    </Container>
  );
};

export default PaceCalculator;
