import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { FaCalculator, FaRunning, FaThermometerHalf, FaExchangeAlt, FaHeartbeat, FaFireAlt } from 'react-icons/fa';

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
`;

const Header = styled.h1`
  text-align: center;
  color: #1a5f7a;
  font-size: 3rem;
  margin-bottom: 30px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
`;

const Intro = styled.p`
  text-align: center;
  color: #495057;
  font-size: 1.1rem;
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const CalculatorGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 40px;
`;

const CalculatorCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 25px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
`;

const CalculatorIcon = styled.div`
  font-size: 2.5rem;
  color: #2a9d8f;
  margin-bottom: 15px;
`;

const CalculatorTitle = styled(Link)`
  font-size: 1.4rem;
  color: #264653;
  text-decoration: none;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  display: block;
  margin-bottom: 10px;

  &:hover {
    color: #2a9d8f;
  }
`;

const Description = styled.p`
  color: #495057;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
`;

const Outro = styled.p`
  text-align: center;
  color: #495057;
  font-size: 1.1rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const Calculators: React.FC = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Pace Calculator",
        "url": "https://airunningtraining.com/paceCalculator"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Training Pace Calculator",
        "url": "https://airunningtraining.com/trainingPaceCalculator"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "Temperature Equivalency Calculator",
        "url": "https://airunningtraining.com/equivalencyCalculator"
      },
      {
        "@type": "ListItem",
        "position": 4,
        "name": "Pace Conversion Chart",
        "url": "https://airunningtraining.com/paceConversionChart"
      },
      {
        "@type": "ListItem",
        "position": 5,
        "name": "Heart Rate Zone Calculator",
        "url": "https://airunningtraining.com/heartRateZoneCalculator"
      },
      {
        "@type": "ListItem",
        "position": 6,
        "name": "Calorie Burn Calculator",
        "url": "https://airunningtraining.com/calorieBurnCalculator"
      }
    ]
  };

  return (
    <>
      <Helmet>
        <title>Running Calculators | Pace, Training, Equivalency Tools | AI Running Training</title>
        <meta 
          name="description" 
          content="Optimize your running with our free calculators. Pace calculator for race planning, training pace calculator for workout optimization, temperature equivalency calculator for adjusting to race conditions and a pace conversion chart between km/h to miles/h." 
        />
        <meta 
          name="keywords" 
          content="running calculators, pace calculator, training pace calculator, temperature equivalency calculator, running tools, race planning, workout optimization, heart rate zones, calorie burn calculator" 
        />
        <link rel="canonical" href="https://airunningtraining.com/calculators" />
        <meta property="og:title" content="Running Calculators | Pace, Training, Equivalency Tools" />
        <meta property="og:description" content="Free running calculators to optimize your training and race performance. Plan your pace, optimize workouts, and adjust for race conditions." />
        <meta property="og:url" content="https://airunningtraining.com/calculators" />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Container>
        <Header>Running Calculators</Header>
        <Intro>
          Optimize your running performance with our free, easy-to-use calculators. Whether you're planning your next race, fine-tuning your training, or preparing for varying weather conditions, we've got you covered:
        </Intro>
        <CalculatorGrid>
          <CalculatorCard>
            <CalculatorIcon><FaCalculator /></CalculatorIcon>
            <CalculatorTitle to="/paceCalculator">Pace Calculator</CalculatorTitle>
            <Description>
              Easily calculate your running pace, time, or distance. Perfect for planning training runs and developing race day strategies. Input any two variables to determine the third.
            </Description>
          </CalculatorCard>
          <CalculatorCard>
            <CalculatorIcon><FaRunning /></CalculatorIcon>
            <CalculatorTitle to="/trainingPaceCalculator">Training Pace Calculator</CalculatorTitle>
            <Description>
              Determine optimal training paces for various workout types based on your recent race times or current fitness level. Train smarter, improve faster, and reduce injury risk by running at the right intensities.
            </Description>
          </CalculatorCard>
          <CalculatorCard>
            <CalculatorIcon><FaThermometerHalf /></CalculatorIcon>
            <CalculatorTitle to="/equivalencyCalculator">Temperature Equivalency Calculator</CalculatorTitle>
            <Description>
              Adjust your expected running pace based on temperature and humidity. Understand how environmental factors impact your performance and set realistic goals for race day, no matter the weather.
            </Description>
          </CalculatorCard>
          <CalculatorCard>
            <CalculatorIcon><FaExchangeAlt /></CalculatorIcon>
            <CalculatorTitle to="/paceConversionChart">Pace Conversion Chart</CalculatorTitle>
            <Description>
              Easily convert running paces from kilometers per hour to miles per hour with this comprehensive pace conversion chart. Ideal for runners of all levels.
            </Description>
          </CalculatorCard>
          <CalculatorCard>
            <CalculatorIcon><FaHeartbeat /></CalculatorIcon>
            <CalculatorTitle to="/heartRateZoneCalculator">Heart Rate Zone Calculator</CalculatorTitle>
            <Description>
              Calculate your heart rate zones for effective training based on your age and resting heart rate.
            </Description>
          </CalculatorCard>
          <CalculatorCard>
            <CalculatorIcon><FaFireAlt /></CalculatorIcon>
            <CalculatorTitle to="/calorieBurnCalculator">Calorie Burn Calculator</CalculatorTitle>
            <Description>
              Estimate the number of calories burned during your run based on your weight, distance, and pace. Great for tracking your energy expenditure and planning nutrition.
            </Description>
          </CalculatorCard>
        </CalculatorGrid>
        <Outro>
          Our calculators are designed to help runners of all levels, from beginners to seasoned marathoners. Use these tools to take the guesswork out of your training and racing, and achieve your running goals more effectively.
        </Outro>
      </Container>
    </>
  );
};

export default Calculators;
