import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

export interface BlogPost {
    id: number;
    title: string;
    summary: string;
    date: string;
    slug: string;
    topic: string;
    tags: string[];
    image: string;
  }


const RelatedBlogsContainer = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const RelatedBlogsHeader = styled.h2`
  font-size: 1.5rem;
  color: #264653;
  margin-bottom: 20px;
  text-align: center;
`;

const RelatedBlogItem = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.3s ease;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  &:hover {
    background-color: #f0f4f8;
  }
`;

const RelatedBlogTitle = styled.h3`
  font-size: 1rem;
  color: #2a9d8f;
  margin-bottom: 5px;
  transition: text-decoration 0.3s ease;

  &:hover {
    text-decoration: underline;
  }
`;

const RelatedBlogThumbnail = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 15px;
`;

const RelatedBlogInfo = styled.div`
  flex: 1;
`;

const RelatedBlogTopic = styled(Link)`
  display: inline-block;
  background-color: #e0f7fa;
  color: #006064;
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 0.7rem;
  margin-bottom: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #b2ebf2;
  }
`;

interface RelatedBlogsProps {
  blogs: BlogPost[];
  topic?: string;
  limit?: number;
}

const RelatedBlogs: React.FC<RelatedBlogsProps> = ({ blogs, topic, limit = 3 }) => {
  const filteredBlogs = topic 
    ? blogs.filter(blog => blog.topic === topic).slice(0, limit)
    : blogs.slice(0, limit);

  return (
    <RelatedBlogsContainer>
      <RelatedBlogsHeader>Related Blogs</RelatedBlogsHeader>
      {filteredBlogs.map(blog => (
        <RelatedBlogItem 
          key={blog.slug} 
          to={`/blog/${blog.topic.toLowerCase().replace(/\s+/g, '-')}/${blog.slug}`}
        >
          <RelatedBlogThumbnail src={blog.image} alt={blog.title} />
          <RelatedBlogInfo>
            <RelatedBlogTitle>{blog.title}</RelatedBlogTitle>
            <RelatedBlogTopic 
              to={`/blogs/${blog.topic.toLowerCase().replace(/\s+/g, '-')}`}
            >
              {blog.topic}
            </RelatedBlogTopic>
          </RelatedBlogInfo>
        </RelatedBlogItem>
      ))}
    </RelatedBlogsContainer>
  );
};

export default RelatedBlogs;
